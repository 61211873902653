import React from "react"
import config from 'react-global-configuration';
import common from '../../utiles/Common'


/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ListTradeItem extends React.Component {

  constructor(){
    super()
    this.onClickHandler = this.onClickHandler.bind(this)
    this.state = {
      closeBtn:false
    }
  }

  componentDidMount(){


  }

  componentWillUnmount(){

  }
  onClickHandler(){
    window.location = "/shop/trade/" + this.props.item.id
  }
  /* Handlers */

  memberStoreChangeHandler(){
  }




  render(){

    const customer = this.props.item.customer_info ? JSON.parse(this.props.item.customer_info) : null
    const name = customer ? customer.name : "-"
    const products_html = this.props.item.products ? this.props.item.products.map((item) => {
        const item_html = item ? <div key={"product_"+item.id} >{item.product.title}</div> : null
        return item_html
    }) : null

    const payment_html = this.props.item.payment_method ? this.props.item.payment_method.name : null
    const paid = this.props.item.payments.length > 0 ? "支払い済み" : "未入金"
    const status = this.props.item.status ? config.get("STATUS_" + this.props.item.status) : "-"
    return(
      <tr onClick={this.onClickHandler}>
        <td>{common.getDateTimeJpString(this.props.item.created_at)}</td>
        <td>{name}</td>
        <td>{products_html}</td>
        <td>{this.props.item.total_price.toLocaleString()}円</td>
        <td>{payment_html}</td>
        <td>{paid}</td>
        <td>{status}</td>
      </tr>
    )
  }
}
