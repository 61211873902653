import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Delivery from "../vo/Delivery"
import PriceItem from "../vo/PriceItem"



var CHANGE_EVENT = "change" // chenge evnetを定数にする
var UPDATED_EVENT = "delivery_updated"


var _item = new Delivery()
var _list = []



var setDetail = (item) =>{
  _item = DeliveryStore.createRecord(item)
}
var setList = (items) => {

  _list = items.map((item) =>{
    return DeliveryStore.createRecord(item)
  })
}

var update = (name,value) => {
  _item = _item.set(name,value)
}

var updateArea = (id,value) => {
  //const areItem = _
  var item = null
  var selected_index = null
  for(var i in _item.price ){
    const pitem = _item.price[i]
    if( "area_" + pitem.id === id){
      item = pitem
      selected_index = i
    }
  }
  if(item){
    item = item.set("price",value)
    var _temp_price_list = _item.price
    _temp_price_list[selected_index] = item
    _item = _item.set("price",_temp_price_list)
  }else{
    const _id = id.split("_")[1]
    _item.set("price",_item.price.push(DeliveryStore.createPriceItemRecord({id:_id,price:value})))
  }
//  console.log(_item.price)
}

var DeliveryStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },
  getList(){
    return _list
  },

  createPriceItemRecord(item){
    return new PriceItem({
      id:item.id,
      price:item.price
    })
  },


  createRecord(item){

    return new Delivery(
    {
      id:item.id,
      name:item.name,
      product_type:item.product_type,
      add_on:item.add_on,
      free_ship_price:item.free_ship_price,
      country:item.country,
      price:item.price.map((pitem) => {return DeliveryStore.createPriceItemRecord(pitem)})

    })
  },



  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.DELIVERY_LIST_LOADED:
      setList(action.list);
      DeliveryStore.emitChange()
      break;
    case Constants.DELIVERY_DETAIL_LOADED:
      setDetail(action.item);
      DeliveryStore.emitChange()
      break;
    case Constants.DELIVERY_UPDATE:
      update(action.name,action.value);
      DeliveryStore.emitChange()
      break
    case Constants.DELIVERY_AREA_UPDATE:
      updateArea(action.id,action.value)
      DeliveryStore.emitChange()
      break
    case Constants.DELIVERY_UPDATED:
      setDetail(action.item);
      DeliveryStore.emitChange(UPDATED_EVENT)
      break

    default:


      // no
  }
})
export default DeliveryStore
