import React from "react"
import {Link } from 'react-router-dom'


/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class Header extends React.Component {

  constructor(){
    super()
    //AuthActions.setAuth()
    this.state = {
    }
  }

  componentDidMount(){
    //console.log(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberStoreChangeHandler)
    //MemberActions.getDetail(AuthStore.getToken())

  }

  componentWillUnmount(){
      //MemberStore.removeChangeListener(this.memberStoreChangeHandler)
  }

  /* Handlers */

  memberStoreChangeHandler(){
  }

  render(){


    return(
      <header>
        <div className="innner">
          <div className="menu">
            <img src="/image/logo_d.png" alt="scrasy"/>
            <Link to="/shop">ホーム</Link>
            <Link to="/shop/product">商品</Link>
            <Link to="/shop/trade">取引</Link>
            <Link to="/shop/category">カテゴリー</Link>
            <Link to="/shop/media">ファイル</Link>
            <Link to="/shop/analytics">分析</Link>
            <Link to="/shop/settings">設定</Link>
            <Link to="/api">API</Link>
          </div>
          <div className="mini-menu"></div>
        </div>
      </header>

    )
  }
}
