import React from "react"
import TradeActions from '../../actions/TradeActions'

import AuthStore from '../../stores/AuthStore'
import Input from './Input'
import Payment from '../../vo/Pay'
import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class PaymentEditor extends React.Component {

  constructor(){
    super()
    this.closeBtnHandler =this.closeBtnHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.changeValueHandler = this.changeValueHandler.bind(this)
    this.changeSelectHandler = this.changeSelectHandler.bind(this)
    this.state = {
      item: null
    }
  }

  componentDidMount(){
    this.setState({
      item:new Payment({
        trade_id:this.props.trade_id
      })
    })
  }

  componentWillUnmount(){

  }

  changeValueHandler(name,value){
    this.setState({
      item:this.state.item.set(name,Number(value))
    })
  }
  changeSelectHandler(e){
    const target = e.target
    this.setState({
      item:this.state.item.set(target.name,Number(target.value))
    })
  }
  closeBtnHandler(){
    this.props.closeBtnHandler()
  }

  clickHandler(){
    TradeActions.payExe(AuthStore.getToken(),this.state.item)
    this.props.closeBtnHandler()
  }

  render(){
    if(!this.state.item) return null
    console.log(this.state.item)
    return(
      <div className="over-flow-back">

      <div className="closebtn" onClick={this.closeBtnHandler}><i className="fas fa-times-circle"></i></div>

      <div className="over-flow-pannel">
        <section className="header-info">
          <h2>支払い履歴登録</h2>
        </section>
        <div className="input-box">
          <label>支払いタイプ</label>
          <select name="pay_type" value={this.state.item.pay_type} onChange={this.changeSelectHandler}>
            <option value="0">-</option>
            <option value="1">{config.get("PAYMENT_TYPE_1")}</option>
            <option value="2">{config.get("PAYMENT_TYPE_2")}</option>
            <option value="3">{config.get("PAYMENT_TYPE_3")}</option>
          </select>
        </div>
        <Input label="金額" type="number" name="amount" value={this.state.item.amount} changeValueHandler={this.changeValueHandler} />

        <div className="input-box">
          <label>支払いステータス</label>
          <select name="status" value={this.state.item.status} onChange={this.changeSelectHandler}>
            <option value="0">-</option>
            <option value="1">{config.get("PAYMENT_STATUS_1")}</option>
            <option value="2">{config.get("PAYMENT_STATUS_2")}</option>
            <option value="3">{config.get("PAYMENT_STATUS_3")}</option>
          </select>
        </div>

        <div className="btn-box">
          <button className="create-btn" onClick = {this.clickHandler}>保存</button>
        </div>
      </div>

    </div>
    )
  }
}
