import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Product from "../vo/Product"
import MediaStore from "./MediaStore"
import MatrixTitle from '../vo/MatrixTitle'
import MatrixValue from '../vo/MatrixValue'


var CHANGE_EVENT = "change" // chenge evnetを定数にする
var UPDATED_EVENT = "product_updated"
var DELETED = "deleted"



var _item = new Product()
var _list = []
var _status = 0;


var setDetail = (item) =>{

  _item = ProductStore.createRecord(item)
  _status = item.status
}
var setList = (items) => {
  const __list = items.map((item) =>{
    return ProductStore.createRecord(item)
  })
  _list = __list
}

var updateList = (name,value,id) => {
  if(name === "x_name_value"){
    var _x_name_val = _item.x_name.value
    _x_name_val[id] = value
    _item = _item.setIn(["x_name","value"],_x_name_val)
  }
  else if(name === "y_name_value"){
    var _y_name_val = _item.y_name.value
    _y_name_val[id] = value
    _item = _item.setIn(["y_name","value"],_y_name_val)
  }else if(name === "matrix_value"){

    const ids = id.split("_")
    const index = ProductStore.getIndexFromMatrix(Number(ids[0]),Number(ids[1]))
    var _stock = _item.stock
    if(index){
      _stock[index] = _stock[index].set("value",Number(value))

    }else{
      _stock.push(new MatrixValue({x:Number(ids[0]),y:Number(ids[1]),value:Number(value)}))
    }

    _item.set("stock", _stock)

  }
}
var update = (name,value) => {
  if(name === "delivery_method"){
    if(_item.delivery_method.indexOf(Number(value)) >= 0){
      const _list = _item.delivery_method
      const index_num = _item.delivery_method.indexOf(Number(value))
      _list.splice(index_num,1)
      _item = _item.set(name,_list)
    }else{
      //console.log(_tmp_list)
      var _tmp_list = _item.delivery_method
      _tmp_list.push(Number(value))
      _item = _item.set(name,_tmp_list )
    }
  }else if(name === "payment_method"){

    if(_item.payment_method.indexOf(Number(value)) >= 0){
      const _list = _item.payment_method
      const index_num = _item.payment_method.indexOf(Number(value))
      _list.splice(index_num,1)
      _item = _item.set(name,_list)

    }else{
      var _tmp_p_list = _item.payment_method
      _tmp_p_list.push(Number(value))
      _item = _item.set(name,_tmp_p_list )
    }
  }else if(name === "options"){
    var index = null
    for(var i in _item.options){
      if(_item.options[i].id === value) index = i
    }
    if(!index){
      const item = ProductStore.getDetailFromList(value)
      if(item){
        var _tmp_op_list = _item.options
        _tmp_op_list.push(item)
        _item = _item.set(name,_tmp_op_list )
      }
    }
  }else if(name === "options_delete"){

    for(var k in _item.options){
      if(_item.options[k].id === value) {
        var _opt_list = _item.options
        _opt_list.splice(k,1)
        _item = _item.set("options",_opt_list)
      }
    }

  }else if(name === "x_name_add"){
    var _x_name_val = _item.x_name.value
    _x_name_val.push("")
    _item = _item.setIn(["x_name","value"],_x_name_val)
  }else if(name === "x_name"){
    _item = _item.setIn(["x_name","name"],value)
  }else if(name === "y_name_add"){
    var _y_name_val = _item.y_name.value
    _y_name_val.push("")
    _item = _item.setIn(["y_name","value"],_y_name_val)
  }else if(name === "y_name"){
    _item = _item.setIn(["y_name","name"],value)
  }else{
    _item = _item.set(name,value)
  }



}

var ProductStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },

  getList(){
    return _list
  },

  getStatus(){
    return _status
  },

  getDetailFromList(id){
    for(var i in _list){
      const item = _list[i]
      if(item.id === id) return item
    }
    return null
  },

  getIndexFromMatrix(x,y){
    for(var i in _item.stock){
      const item = _item.stock[i]
      if(item.x === x && item.y === y) return i
    }
    return null
  },

  createMatrixValueRecord(item){
    return new MatrixValue({
      x:item.x,
      y:item.y,
      value:item.value
    })
  },

  createMatrixRecord(item){
    return new MatrixTitle({
      name:item.name,
      value:item.value
    })
  },
  createRecord(item){

    const medias = item.medias ? item.medias.map((media) => {
      return  (MediaStore.createRecord(media))
    }) : []
    return new Product(
    {
      id:item.id,
      title:item.title,
      sub_title:item.sub_title,
      description:item.description,
      category:item.category ? item.category.id : null,
      price:item.price,
      unit:item.unit,
      status:item.status,
      x_name:item.x_name ? ProductStore.createMatrixRecord(item.x_name) : new MatrixTitle(),
      y_name:item.y_name ? ProductStore.createMatrixRecord(item.y_name) : new MatrixTitle(),
      stock:item.stock ? item.stock.map((item) => {return ProductStore.createMatrixValueRecord(item)}) : [],
      payment_method:item.payment_method && item.payment_method.length > 0 ? item.payment_method.split(",").map((m) => {return Number(m)}) : [],
      delivery_method:item.delivery_method && item.delivery_method.length > 0 ? item.delivery_method.split(",").map((d) => {return Number(d)}) : [],
      updated_at:item.updated_at,
      medias:medias,
      options:item.option_product
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.PLODUCT_LIST_LOADED:
      setList(action.list);
      ProductStore.emitChange()
      break;
    case Constants.PLODUCT_DETAIL_LOADED:

      setDetail(action.item);
      ProductStore.emitChange()
      break;
    case Constants.PLODUCT_DETAIL_UPDATED:
      update(action.name,action.value);
      ProductStore.emitChange()
      break
    case Constants.PLODUCT_DETAIL_UPDATED_LIST:
      updateList(action.name,action.value,action.id);
      ProductStore.emitChange()
      break
    case Constants.PLODUCT_DELETED:
      ProductStore.emitChange(DELETED)
    break
    case Constants.PLODUCT_DETAIL_UPDATED_EXE:
      setDetail(action.item);
      ProductStore.emitChange(UPDATED_EVENT)
      break
    default:


      // no
  }
})
export default ProductStore
