import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';

var TradeActions = {

  getDetail(token,id){

    fetch(config.get("API_CART_PATH") + config.get("API_TRADE_DETAIL").url + "/" + id, {
      method: config.get("API_TRADE_DETAIL").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){
        AppDispatcher.dispatch({
          actionType: Constants.TRADE_DETAIL_LOADED,
          item:data,
          id:id
        })
      }else if(data === null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  getList(token, status = null, limit = null, offset = null){

    const status_query = status ? "&status=" + status : ""
    const limit_query = limit ? "&limit=" + limit : ""
    const offset_query = offset ? "&offset=" + offset : ""

    //console.log(config.get("API_CART_PATH") + config.get("API_TRADE_LIST").url + "?p=1" + status_query + limit_query + offset_query)

    fetch(config.get("API_CART_PATH") + config.get("API_TRADE_LIST").url + "?p=1" + status_query + limit_query + offset_query, {
      method: config.get("API_TRADE_LIST").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null && data.status && data.status === 1){
        AppDispatcher.dispatch({
          actionType: Constants.TRADE_LIST_LOADED,
          list:data.trades,
          total:data.total
        })
      }else if(data === null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  updateExe(token,id,status){
    const item = {status:Number(status),trade_id:id}
    fetch(config.get("API_CART_PATH") + config.get("API_TRADE_UPDATE").url, {
      method: config.get("API_TRADE_UPDATE").method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data != null && data.status && data.status === 1){
        TradeActions.getDetail(token,id)
      }else if(data === null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },

payExe(token,item){

  fetch(config.get("API_CART_PATH") + config.get("API_PAY").url, {
    method: config.get("API_PAY").method,
    headers: {
     'Authorization': 'Bearer ' + token,
     'Content-Type': 'application/json'
    },
    body: JSON.stringify(item)
  }).then(function(response) {
    console.log(response)
    if(response.status === 200){
      return response.json()
    }else if(response.status === 401){
      return null
    }else{
      return response.status
    }
  }).then(function(data) {

    if(data != null && data.status && data.status === 1){
      TradeActions.getDetail(token,item.trade_id)
    }else if(data === null){
      window.location = "/"
    }else{
      AppDispatcher.dispatch({
        actionType: Constants.API_ERROR,
        code:data
      })
    }
  })
},

  update(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.TRADE_UPDATE,
      name:name,
      value:value
    })
  },


}

export default TradeActions
