import React from "react"
import AuthStore from '../../stores/AuthStore'
import CategoryActions from '../../actions/CategoryActions'
import CategoryStore from '../../stores/CategoryStore'

import Category from '../../vo/Category'
import Input from './Input'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class CategoryCreater extends React.Component {

  constructor(){
    super()
    //this.handleChangeFile = this.handleChangeFile.bind(this)
    this.closeBtnHandler = this.closeBtnHandler.bind(this)
    this.changeValueHandler =this.changeValueHandler.bind(this)
    this.caetegoryChangeHandler = this.caetegoryChangeHandler.bind(this)
    this.buttonClickHander = this.buttonClickHander.bind(this)
    //AuthActions.setAuth()
    this.state = {
      item:Category(),
      message:null
    }
  }

  componentDidMount(){
    //console.log(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberStoreChangeHandler)
    //MemberActions.getDetail(AuthStore.getToken())
    CategoryStore.addChangeListener(this.caetegoryChangeHandler,"update")
  }

  componentWillUnmount(){
    CategoryStore.removeChangeListener(this.caetegoryChangeHandler,"update")
      //MemberStore.removeChangeListener(this.memberStoreChangeHandler)
  }

  /* Handlers */
  caetegoryChangeHandler(){
    this.setState({
      is_processing:false,
      item:CategoryStore.getDetail()
    })
  }

  closeBtnHandler(){
    this.setState({
      item:Category(),
      message:null
    })
    this.props.closeBtnHandler()
  }
  changeValueHandler(name,value){

    CategoryActions.update(name,value)
  }
  selectChangeHandler(e){
    const target = e.target
    var parent_id = null
    if(target.value !== 0){
      parent_id = Number(target.value)
    }
    CategoryActions.update("parent_id",parent_id)
    //console.log(e.value)
  }

  buttonClickHander(){

    CategoryActions.updateExe(AuthStore.getToken(), this.state.item)
    this.setState({
      item:Category(),
      message:null
    })
    this.props.closeBtnHandler()
  }

  getInputItem(item,step){
    var spacer = ""
    for(var i = 0; i < step; i++){
      spacer = spacer + "　"
    }
    spacer = spacer + "|-"
    var items = []
    items.push(<option value={item.id} key={"opt_" + item.id}>{spacer + item.name}</option> )
    for(var k in item.children ){
      items.push(this.getInputItem(item.children[k], step+1))
    }

    return items

  }


  render(){

    if(!this.props.is_display){
      return null
    }

    const parents = this.props.list.map((item) => {
      return this.getInputItem(item,0)
    })
    return(
      <div class="over-flow-back">
        <div className="uploader category">
          <div className="closebtn" onClick={this.closeBtnHandler}><i className="fas fa-times-circle"></i></div>
          <Input label="カテゴリー名" type="text" name="name" value={this.state.item.name} changeValueHandler={this.changeValueHandler} />
          <div className="input-box">
            <label>親カテゴリー</label>
            <select name="parent_id" value={this.state.item.parent_id} onChange={this.selectChangeHandler}>
              <option value="0">-</option>
              {parents}
            </select>
          </div>
          <button className="create-btn middiup" onClick={this.buttonClickHander}>送信</button>
        </div>
      </div>
    )
  }
}
