import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'

var AuthActions = {

  setAuth(){
    var is_auth = false;
    let token = cookie.load('token')
    let refresh_token = cookie.load('refresh_token')

    if(token !== undefined && token !== ""){
      is_auth = true
      AppDispatcher.dispatch({
        actionType: Constants.AUTH, // 誰に
        is_auth: is_auth,
        token: token,
        refresh_token: refresh_token
      })
    }else{
      window.location = "/"
    }

  },

  auth(loginname,password){
    //Login
    var data = new FormData()
    data.append('grant_type', "password")
    data.append('username', loginname)
    data.append('password', password)
    data.append('client_id', "store")
    data.append('client_secret', "store")
    data.append('scope', "shop")

    fetch(config.get("API_AUTH_PATH") + config.get("API_AUTH"), {
      method: 'POST',
      body: data
    }).then(function(response) {

      if(response.status !== 200){
        return false;
      }else{
        return response.json()
      }
    }).then(function(data) {

      var is_auth = false;
      var token = "";
      var refresh_token = "";
      if(data.access_token){
        is_auth = true;
        token = data.access_token
        refresh_token = data.refresh_token
        cookie.save('token', token, { path: '/' })
        cookie.save('refresh_token', refresh_token, { path: '/' })

        AppDispatcher.dispatch({
          actionType: Constants.AUTH, // 誰に
          is_auth: is_auth,
          token: token,
          refresh_token: refresh_token
        })
      }else{
        cookie.remove('token',{ path: '/' })
        cookie.remove('refresh_token',{ path: '/' })
        AppDispatcher.dispatch({
          actionType: Constants.AUTH_NOUSER,
        })
      }
    });
  },




  logout(){
    cookie.remove('token',{ path: '/' })
    cookie.remove('refresh_token',{ path: '/' })
    window.location = "/"
  },


}

export default AuthActions
