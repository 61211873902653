import keyMirror from "keymirror"

export default keyMirror({
  ///Messagections
  MESSAGE_CREATE:null,

  ///AUTH
  AUTH: null,
  AUTH_LOGOUT:null,
  AUTH_ERROR:null,
  AUTH_NOUSER:null,
  ACCOUNT_UPDATE:null,
  ACCOUNT_CREATED:null,
  UPDATE_CARD:null,
  UPDATE_TOKEN:null,
  PAYMENT_DONE:null,
  ERROR_CARD:null,
  ERROR_PAY:null,

  PLODUCT_LIST_LOADED:null,
  PLODUCT_DETAIL_LOADED:null,
  PLODUCT_DETAIL_UPDATED:null,
  PLODUCT_DETAIL_UPDATED_LIST:null,
  PLODUCT_DETAIL_UPDATED_EXE:null,
  PLODUCT_DELETED:null,

  FILE_LIST_LOADED:null,
  FILE_ADDED:null,
  FILE_ATTACHED:null,

  CATEGORY_LIST_LOADED:null,
  CATEGORY_DETAIL_UPDATED:null,
  CATEGORY_SET_DETAIL_ID:null,

  DELIVERY_LIST_LOADED:null,
  DELIVERY_DETAIL_LOADED:null,
  DELIVERY_UPDATED:null,
  DELIVERY_UPDATE:null,
  DELIVERY_AREA_UPDATE:null,

  PAYMENT_LIST_LOADED:null,
  PAYMENT_DETAIL_LOADED:null,
  PAYMENT_UPDATED:null,
  PAYMENT_UPDATE:null,


  SHOP_DETAIL_LOADED:null,
  SHOP_DETAIL_UPDATED:null,

  NOTICE_NEW:null,
  NOTICE_UPDATED:null,

  SETTING_LIST_LOADED:null,
  SETTING_DETAIL_UPDATED:null,
  MAILTEMPLATE_LIST_LOADED:null,
  MAILTEMPLATE_DETAIL_LOADED:null,
  MAILTEMPLATE_UPDATED:null,
  MAILTEMPLATE_SAVED:null,
  
  TRADE_DETAIL_LOADED:null,
  TRADE_LIST_LOADED:null,
  TRADE_UPDATE:null,

  ANALYTICS_LIST_LOADED :null,

  API_GET_ITEM:null,
  API_ERROR:null
})
