import React from "react"

import Header from "./parts/Header"
import AuthActions from '../actions/AuthActions'
import TradeActions from '../actions/TradeActions';
import AuthStore from '../stores/AuthStore'
import TradeStore from '../stores/TradeStore'
import ListTradeItem from './parts/ListTradeItem'
import Paging from './parts/Paging'
import config from 'react-global-configuration';

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class TradeApp extends React.Component {

  constructor({match}){
    super()
    this.tradeStoreChangeHandler = this.tradeStoreChangeHandler.bind(this)
    this.pagingClikcHandler = this.pagingClikcHandler.bind(this)
    AuthActions.setAuth()

    const status = match ? match.params.status : null
    this.state = {
      list:[],
      total:null,
      status:status,
      offset:null
    }

  }

  componentDidMount(){

    TradeStore.addChangeListener(this.tradeStoreChangeHandler)
    const params = new URLSearchParams(window.location.search);
    const offset = params.get('offset') ? params.get('offset') * config.get("LIMIT") :null
    TradeActions.getList(AuthStore.getToken(),this.state.status,config.get("LIMIT"),offset)
    this.setState({
      offset:params.get('offset')
    })

  }


  componentWillUnmount(){
    TradeStore.removeChangeListener(this.tradeStoreChangeHandler)
  }

  tradeStoreChangeHandler(){
    this.setState({
      list:TradeStore.getList(),
      total:TradeStore.getTotal()
    })

  }
  pagingClikcHandler(id){
    const status = this.state.status ? (this.state.status + "/") :""
    window.location = "/shop/trade/search/" +  status + "?offset=" + id
  }
  render(){
    const listHtml = this.state.list.map((item) => {
      return <ListTradeItem item={item} key={"trade_" + item.id}/>
    })


    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-1">
              <section className="header-info">
                <h2>取引一覧</h2>
              </section>

              <table className="list-table">
                <tbody>
                  <tr><th>注文日時</th><th>注文者</th><th>購入商品</th><th>合計金額</th><th>支払い方法</th><th>支払い状況</th><th>ステータス</th></tr>
                  {listHtml}
                </tbody>
              </table>
              <Paging total={this.state.total} handler={this.pagingClikcHandler} offset={this.state.offset}/>

            </div>
          </main>
      </div>
    )
  }
}
