
const config = {
    COMPANY_ID:   3,
    API_JSON:                 '/json/',


    API_AUTH:                   '/oauth/auth',

    API_SETTING_LIST:        {url:'/shop/settings',method:'GET'},
    API_SETTING_UPDATE:      {url:'/shop/settings',method:'POST'},
    API_SETTING_DELETE:      {url:'/shop/settings/',method:'DELETE'},
    API_PRODUT:              '/shop/products',
    API_PRODUT_LIST:         {url:'/shop/products',method:'GET'},
    API_PRODUCT_DETAIL:      {url:'/shop/products/',method:'GET'},
    API_PRODUCT_UPDATE:      {url:'/shop/products',method:'POST'},
    API_FILE_UPLOAD:         {url:'/shop/files',method:'POST'},
    API_FILE_DELETE:         {url:'/shop/files/delete',method:'DELETE'},
    API_FILE_ATTACH:         {url:'/shop/products/media',method:'POST'},
    API_FILE_LIST:           {url:'/shop/files',method:'GET'},
    API_CATEGORY_LIST:       {url:'/shop/categories',method:'GET'},
    API_CATEGORY_DETAIL:     {url:'/shop/categories',method:'GET'},
    API_CATEGORY_UPDATE:     {url:'/shop/categories',method:'POST'},
    API_CATEGORY_DELETE:     {url:'/shop/categories/delete',method:'POST'},
    API_SHOP_DETAIL:         {url:'/shop',method:'GET'},
    API_SHOP_UPDATE:         {url:'/shop',method:'POST'},

    API_TEMPLATE_LIST:       {url:'/shop/mail/templates',method:'GET'},
    API_TEMPLATE_DETAIL:     {url:'/shop/mail/templates',method:'GET'},
    API_TEMPLATE_UPDATE:     {url:'/shop/mail/templates',method:'POST'},
    API_DELIVERY_LIST:       {url:'/shop/deliveries',method:'GET'},
    API_DELIVERY_DETAIL:     {url:'/shop/deliveries',method:'GET'},
    API_DELIVERY_UPDATE:     {url:'/shop/deliveries',method:'POST'},
    API_DELIVERY_DELETE:     {url:'/shop/deliveries/delete',method:'POST'},
    API_PAYMENT_LIST:        {url:'/shop/payments',method:'GET'},
    API_PAYMENT_DETAIL:      {url:'/shop/payments',method:'GET'},
    API_PAYMENT_UPDATE:      {url:'/shop/payments',method:'POST'},
    API_PAYMENT_DELETE:      {url:'/shop/payments/delete',method:'POST'},
    API_TRADE_LIST:          {url:'/shop/trades',method:'GET'},
    API_TRADE_DETAIL:        {url:'/shop/trades',method:'GET'},
    API_TRADE_UPDATE:        {url:'/shop/trades',method:'POST'},
    API_PAY:                 {url:'/shop/pay',method:'POST'},

    API_ANALYTICS:          '/shop/analytics',

    status_0: "非公開",
    status_1: "公開",

    NOTICE_order_created:"注文完了時",
    NOTICE_order_canceled:"注文キャンセル時",
    NOTICE_paid:"決済完了時",
    NOTICE_question:"お問い合わせメール返信",
    NOTICE_delivered:"発送完了時",

    STATUS_0: "-",
    STATUS_1: "注文受付",
    STATUS_10: "発送済み",
    STATUS_20: "キャンセル",

    PRODUCT_TYPE_1:"物販商品",
    PRODUCT_TYPE_2:"デジタル商品",

    PAYMENT_TYPE_1:"銀行振込",
    PAYMENT_TYPE_2:"カード支払い",
    PAYMENT_TYPE_3:"代金引き換え",

    PAYMENT_STATUS_1:"支払い済み",
    PAYMENT_STATUS_2:"一部支払い",
    PAYMENT_STATUS_3:"支払い取り消し",

    LIMIT:50,

    COUNTRY_jp:"日本",

    WEEK_0 : '日',
    WEEK_1 : '月',
    WEEK_2 : '火',
    WEEK_3 : '水',
    WEEK_4 : '木',
    WEEK_5 : '金',
    WEEK_6 : '土',

    PREF_1 : '北海道',
    PREF_2 : '青森県',
    PREF_3 : '岩手県',
    PREF_4 : '宮城県',
    PREF_5 : '秋田県',
    PREF_6 : '山形県',
    PREF_7 : '福島県',
    PREF_8 : '茨城県',
    PREF_9 : '栃木県',
    PREF_10 : '群馬県',
    PREF_11 : '埼玉県',
    PREF_12 : '千葉県',
    PREF_13 : '東京都',
    PREF_14 : '神奈川県',
    PREF_15 : '新潟県',
    PREF_16 : '富山県',
    PREF_17 : '石川県',
    PREF_18 : '福井県',
    PREF_19 : '山梨県',
    PREF_20 : '長野県',
    PREF_21 : '岐阜県',
    PREF_22 : '静岡県',
    PREF_23 : '愛知県',
    PREF_24 : '三重県',
    PREF_25 : '滋賀県',
    PREF_26 : '京都府',
    PREF_27 : '大阪府',
    PREF_28 : '兵庫県',
    PREF_29 : '奈良県',
    PREF_30 : '和歌山県',
    PREF_31 : '鳥取県',
    PREF_32 : '島根県',
    PREF_33 : '岡山県',
    PREF_34 : '広島県',
    PREF_35 : '山口県',
    PREF_36 : '徳島県',
    PREF_37 : '香川県',
    PREF_38 : '愛媛県',
    PREF_39 : '高知県',
    PREF_40 : '福岡県',
    PREF_41 : '佐賀県',
    PREF_42 : '長崎県',
    PREF_43 : '熊本県',
    PREF_44 : '大分県',
    PREF_45 : '宮崎県',
    PREF_46 : '鹿児島県',
    PREF_47 : '沖縄県',


    BASE_URL: 'http://localhost:3000',

    API_AUTH_PATH:      process.env.REACT_APP_API_AUTH_PATH ?  process.env.REACT_APP_API_AUTH_PATH : 'https://auth.scrasy.jp',
    API_PATH:           process.env.REACT_APP_API_CATALOG_PATH ? process.env.REACT_APP_API_CATALOG_PATH  : 'https://catalog.scrasy.jp',
    API_CART_PATH:      process.env.REACT_APP_API_CART_PATH ?  process.env.REACT_APP_API_CART_PATH : 'https://cart.scrasy.jp',
    SHOP_API_PATH:      process.env.REACT_APP_API_SHOP_PATH ?  process.env.REACT_APP_API_SHOP_PATH :  'https://shop.scrasy.jp',

}

/*
const config_dev = {

    API_AUTH_PATH: 'http://localhost:9020',
    API_PATH: 'http://localhost:9030',
    API_CART_PATH: 'http://localhost:9040',
    BASE_URL: 'http://localhost:3000',
    SHOP_API_PATH:'http://localhost:9050',
    API_EXTENTION: "",
    PAY_JP_KEY: 'pk_test_02e061bf28f85d4814ce48fd',
    CALL_BACK_NEW: 'https://localhost:3000/signup',
    CALL_BACK_LOGIN: 'https://localhost:3000'
};

const config_production = {
  API_AUTH_PATH: 'https://auth.scrasy.jp',
  API_PATH: 'https://catalog.scrasy.jp',
  API_CART_PATH: 'https://cart.scrasy.jp',
  BASE_URL: 'https://scrasy.jp',
  SHOP_API_PATH:'https://shop.scrasy.jp',
  API_EXTENTION: "",
  PAY_JP_KEY: 'pk_test_02e061bf28f85d4814ce48fd',
  CALL_BACK_NEW: 'https://bbc.thehousehayama.com/signup',
  CALL_BACK_LOGIN: 'https://bbc.thehousehayama.com'
};
*/


/*

var config = {}

if(process.env.NODE_ENV === "development"){
  config = Object.assign(config_default,config_dev)
}else if(process.env.NODE_ENV === "production"){
  config = Object.assign(config_default,config_production)
}
*/

export default config;
