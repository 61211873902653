import React from "react"
import config from 'react-global-configuration';



/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ListMediaItem extends React.Component {

  constructor(){
    super()
    this.onClickHandler = this.onClickHandler.bind(this)
    this.state = {
      closeBtn:false
    }
  }

  componentDidMount(){


  }

  componentWillUnmount(){

  }
  onClickHandler(){
    window.location = "/shop/product/" + this.props.item.id
  }
  /* Handlers */

  memberStoreChangeHandler(){
  }
  getMainImage(){
    var result = null
    for(var i in this.props.item.medias ){
      if(this.props.item.medias[i].media_key === "main_image"){
        result =  this.props.item.medias[i]
      }
    }

    return result
  }



  render(){
    const image = this.getMainImage()
    const imghtml = image ? <img src={image.url} alt="product"/> : null
    const category_name = this.props.item.category ? this.props.item.category.name : "-"
    return(
      <li onClick={this.onClickHandler}>
        <div className="product-list-image">{imghtml}</div>
        <div className="product-list-title">{this.props.item.title}</div>
        <div className="product-list-sub_title">{this.props.item.sub_title}</div>
        <div className="product-list-category">{category_name}</div>
        <div className="product-list-price">{this.props.item.price.toLocaleString()}円</div>
        <div className="product-list-status">{config.get("status_" + this.props.item.status)}</div>
      </li>
    )
  }
}
