import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"

import Constants from "../constants/Constants"
import assign from "object-assign"
import Shop from "../vo/Shop"
import GroupStore from "./GroupStore"


var CHANGE_EVENT = "change" // chenge evnetを定数にする


var _item = null


var setDetail = (item) =>{
  _item = ShopStore.createRecord(item)
}

var update = (name,value) => {
  _item = _item.set(name,value)
}


var ShopStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },


  createRecord(item){


    return new Shop(
    {
      id:item.id,
      name:item.name,
      tel:item.tel,
      shop_key:item.shop_key,
      mail:item.mail,
      postcode:item.postcode,
      address1:item.address1,
      address2:item.address2,
      created_at:item.created_at,
      modify_at:item.modify_at,
      discription:item.discription,
      open_flg:item.open_flg,
      group:GroupStore.createRecord(item.group)
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする


    case Constants.SHOP_DETAIL_UPDATED:
      update(action.name,action.value);
      ShopStore.emitChange()
      break
    case Constants.SHOP_DETAIL_LOADED:
      console.log(action.item)
      setDetail(action.item);
      ShopStore.emitChange()
      break


    default:


      // no
  }
})
export default ShopStore
