import React from "react"

import Header from "./parts/Header"
import ApiSideMenuSetting from "./parts/ApiSideMenuSetting"

import ApiStore from "../stores/ApiStore"
import ApiActions from "../actions/ApiActions"


export default class ApiApp extends React.Component {

  constructor({match}){
    super()
    this.apiStoreChangeHandler = this.apiStoreChangeHandler.bind(this)
    this.state = {
      category:match.params.category,
      api:match.params.api,
      item:null
    }
  }

  componentDidMount(){
    ApiStore.addChangeListener(this.apiStoreChangeHandler)
    ApiActions.getDetail(this.state.category, this.state.api)

  }

  updateParam(){
    ApiActions.getDetail(this.props.match.params.category, this.props.match.params.api)
  }


  componentWillUnmount(){
    ApiStore.removeChangeListener(this.apiStoreChangeHandler)
  }

  apiStoreChangeHandler(){
    this.setState({
      item:ApiStore.getDetail()
    })
  }


  render(){
    if(!this.state.item) return null

    const error_html = this.state.item.response.error.map((item) => {
        return <tr key={"error_" + item.code}><td>{item.code}</td><td>{item.message}</td></tr>
    })

    const params_html =this.state.item.request.parameter ?  this.state.item.request.parameter.map((item) => {
      return <tr key={"tr_" + item.name}><td>{item.name}</td><td>{item.description}</td><td>{item.type}</td><td>{item.required ? "必須": ""}</td><td dangerouslySetInnerHTML={{__html: item.comment}}></td></tr>
    }) : null
    const request_parameter = this.state.item.request.parameter ?
      <div>
        <h3>リクエストパラメータ</h3>
        <table>
          <tbody>
          <tr><th>パラメーター</th><th>説明</th><th>型</th><th>必須</th><th>備考</th></tr>
          {params_html}
          </tbody>
        </table>
      </div>
      : null

    const request_sample = this.state.item.request.sample ?
      <div><h3>リクエストサンプル</h3>
      <div className="one-line"><pre><code>{JSON.stringify(this.state.item.request.sample,undefined,5)}</code></pre></div></div>
      : null

    const request_sample_str = this.state.item.request.sample_ste ?
        <div><h3>リクエストサンプル</h3>
        <div className="one-line">{this.state.item.request.sample_ste}</div></div>
        : null

    const request_header = this.state.item.request.header ?
        <div><h3>リクエストヘッダー</h3>

        <table>
          <tbody>
             {this.state.item.request.header.map((item) => <tr><td>{item.name}</td><td>{item.value}</td></tr>)}
          </tbody>
        </table>
        </div>
        : ""

    const response_html = this.state.item.response.sample ?
      <div className="one-line"><pre><code>{JSON.stringify(this.state.item.response.sample,undefined,5)}</code></pre></div>
      : <div className="one-line">{this.state.item.response.sample_text}</div>

    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <ApiSideMenuSetting updateParam = {this.updateParam} category={this.state.category} api={this.state.api} />
              </section>

              <section className="main-2-pannel api">
                <section className="header-info">
                  <h2>{this.state.item.name}</h2>
                </section>

                <section className="left-pannel">

                  <h3>URL</h3>
                  <div className="one-line">{this.state.item.url}</div>

                  <h3>メソッド</h3>
                  <div className="one-line">{this.state.item.method}</div>

                  <h3>スコープ</h3>
                  <div className="one-line">{this.state.item.scope}</div>

                  {request_parameter}
                  {request_header}
                  {request_sample}
                  {request_sample_str}

                  <h3>レスポンスサンプル</h3>
                  {response_html}

                  <h3>エラー</h3>
                  <table>
                    <tbody>
                    <tr><th>エラーコード</th><th>メッセージ</th></tr>
                    {error_html}
                    </tbody>
                  </table>
                </section>

              </section>
            </div>
          </main>
      </div>
    )
  }
}
