import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration'
import Category from "../vo/Category"


var CategoryActions = {

  getList(token){
    fetch(config.get("API_PATH") + config.get("API_CATEGORY_LIST").url, {
      method: config.get("API_CATEGORY_LIST").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){

        AppDispatcher.dispatch({
          actionType: Constants.CATEGORY_LIST_LOADED,
          list:data.categories
        })
      }else if(data === null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  updateExe(token,item){

    fetch(config.get("API_PATH") + config.get("API_CATEGORY_UPDATE").url, {
      method: config.get("API_CATEGORY_UPDATE").method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null ){
        CategoryActions.getList(token)
      }else if(data == null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },
  delete(token,category_id){
    const item = {id:category_id}
    fetch(config.get("API_PATH") + config.get("API_CATEGORY_DELETE").url, {
      method: config.get("API_CATEGORY_DELETE").method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null ){
        CategoryActions.getList(token)
      }else if(data === null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },
  update(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.CATEGORY_DETAIL_UPDATED,
      name:name,
      value:value
    })
  },

  getDetail(token,id){
    fetch(config.get("API_PATH") + config.get("API_CATEGORY_DETAIL").url + "/" + id, {
      method: config.get("API_CATEGORY_DETAIL").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){

        AppDispatcher.dispatch({
          actionType: Constants.CATEGORY_DETAIL_LOADED,
          item:data
        })
      }else if(data == null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },
  setNew(){
    AppDispatcher.dispatch({
      actionType: Constants.CATEGORY_DETAIL_LOADED,
      item:new Category()
    })
  }






}

export default CategoryActions
