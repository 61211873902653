import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"

import assign from "object-assign"
import Group from "../vo/Group"


var CHANGE_EVENT = "change" // chenge evnetを定数にする

var _item = null





var GroupStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },


  createRecord(item){


    return new Group(
    {
      id:item.id,
      name:item.name,
      tel:item.tel,
      shop_group_key:item.shop_group_key
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    /*
    case Constants.SHOP_DETAIL_UPDATED:
      update(action.name,action.value);
      ShopStore.emitChange(UPDATE_EVENT)
      break
    case Constants.SHOP_DETAIL_LOADED:
      setDetail(action.item);
      ShopStore.emitChange()
      break
      */

    default:


      // no
  }
})
export default GroupStore
