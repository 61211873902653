import { Record } from 'immutable'

const Delivery = Record({
  id:null,
  name:null,
  price:[],
  product_type: null,
  add_on: null,
  country:"jp",
  free_ship_price:null
});
export default Delivery;
