import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"

var CHANGE_EVENT = "change" // chenge evnetを定数にする
var ACCOUNT_UPDATE = "account_update"
var ACCOUNT_CREATED = "account_created"


///AUTH
var _is_login = false
var _token = ""
var _refresh_token = ""

var _user_info = null
/*
*  ここから処理本体を書き始める
*/

var auth = (is_auth,token,refresh_token) => {
  _is_login = is_auth
  _token = token
  _refresh_token = refresh_token
}

var setDetail= (name,value) =>{
  _user_info =  _user_info.set(name,value)
}


var logout = () =>{
  _is_login = false
  _token = ""
  _refresh_token = ""
}



var AuthStore = assign({},EventEmitter.prototype,{

  /*getAll(){ // 今のtodo全てを返す
    return _todos
  },*/

  isAuth(){
    return _is_login
  },

  getToken(){
    return _token
  },

  getUserInfo(){
    return _user_info
  },

  getRefreshToken(){
      return _refresh_token
  },




  emitChange(event_type = CHANGE_EVENT ){ // 何かアクションがあったことを知らせる
    this.emit(event_type)
  },

  addChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーに追加
    this.on(event_type, callback)
  },

  removeChangeListener(callback, event_type = CHANGE_EVENT ){ // リスナーから削除
    this.removeListener(event_type, callback)
  }

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.AUTH:
      auth(action.is_auth, action.token, action.refresh_token);
      AuthStore.emitChange()
      break;
    case Constants.AUTH_NOUSER:
      AuthStore.emitChange()
      break;
    case Constants.ACCOUNT_UPDATE:

      setDetail(action.name,action.value)
      AuthStore.emitChange(ACCOUNT_UPDATE)
      break;
    case Constants.ACCOUNT_CREATED:
      AuthStore.emitChange(ACCOUNT_CREATED)
      break;

    case Constants.AUTH_LOGOUT:
      logout();
      AuthStore.emitChange(ACCOUNT_UPDATE)
      break;

    default:
      // no
  }
})
export default AuthStore
