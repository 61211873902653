import React from "react"

import Header from "./parts/Header"
import AuthActions from '../actions/AuthActions'
import CategoryActions from '../actions/CategoryActions';
import AuthStore from '../stores/AuthStore'
import CategoryStore from '../stores/CategoryStore'
import CategoryItem from './parts/CategoryItem'
import CategoryCreater from './parts/CategoryCreater'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class CategoryApp extends React.Component {

  constructor(){
    super()
    this.categoryStoreChangeHandler = this.categoryStoreChangeHandler.bind(this)
    this.displayNew = this.displayNew.bind(this)
    this.closeBtnHandler = this.closeBtnHandler.bind(this)
    AuthActions.setAuth()
    this.state = {
      list:[],
      display_new:false
    }
  }

  componentDidMount(){
    CategoryStore.addChangeListener(this.categoryStoreChangeHandler)
    CategoryActions.getList(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberListChangeHandler)
    //MemberActions.getMemberList(AuthStore.getToken())

  }


  componentWillUnmount(){
    CategoryStore.removeChangeListener(this.categoryStoreChangeHandler)
  }

  categoryStoreChangeHandler(){
    this.setState({
      list:CategoryStore.getList()
    })

  }
  displayNew(){
    this.setState({
      display_new:true
    })
  }
  closeBtnHandler(){
    this.setState({
      display_new:false
    })
  }
  render(){
    const listHtml = this.state.list.map((item) => {
      return <CategoryItem item={item} key={"category_" + item.id}/>
    })


    return(
      <div>
        <CategoryCreater closeBtnHandler={this.closeBtnHandler} is_display={this.state.display_new} list={this.state.list}/>
        <Header />
          <main>
            <div className="main-pannel-1">
              <section className="header-info">
                <div className="head-right">
                  <div className="link" onClick={this.displayNew}><i className="fas fa-plus-circle"></i> 新規カテゴリー</div>
                </div>
              </section>

                <ul className="product-item">
                  {listHtml}
                </ul>

            </div>
          </main>
      </div>
    )
  }
}
