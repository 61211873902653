import { Record } from 'immutable'
import MatrixTitle from './MatrixTitle'
const Product = Record({
  id:undefined,
  title:null,
  sub_title:null,
  description:null,
  category:null,
  price:null,
  status:null,
  stock:[],
  unit:null,
  payment_method:[],
  delivery_method:[],
  updated_at:null,
  options:[],
  option_product:[],
  medias:[],
  x_name:new MatrixTitle(),
  y_name:new MatrixTitle()
});
export default Product;
