import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Product from "../vo/Product"

var ProductActions = {

  getList(token){
    fetch(config.get("API_PATH") + config.get("API_PRODUT_LIST").url, {
      method: config.get("API_PRODUT_LIST").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){

        AppDispatcher.dispatch({
          actionType: Constants.PLODUCT_LIST_LOADED,
          list:data.products
        })
      }else if(data === null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },

  getDetail(token,id){
    fetch(config.get("API_PATH") + config.get("API_PRODUCT_DETAIL").url + id, {
      method: config.get("API_PRODUCT_DETAIL").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){

        AppDispatcher.dispatch({
          actionType: Constants.PLODUCT_DETAIL_LOADED,
          item:data
        })
      }else if(data === null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },
  updateExe(token,item){
    console.log(item)
    item = item.options && item.options.length > 0 ? item.set("option_product", item.options.map((item) => {return item.id}) ) : item

    //item = item.category ? item.set("category", Number(item.category.id) ) : item

    const method:String = item.id ? "PATCH" : "POST"
    const url:String = item.id ? config.get("API_PATH") + config.get("API_PRODUCT_UPDATE").url + "/" + item.id : config.get("API_PATH") + config.get("API_PRODUCT_UPDATE").url

    fetch(url, {
      method: method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null && data.status && data.status === 1){

        AppDispatcher.dispatch({
          actionType: Constants.PLODUCT_DETAIL_UPDATED_EXE,
          item:data
        })
      }else if(data == null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },

  delete(token,id){
    fetch(config.get("API_PATH") + config.get("API_PRODUT") + "/" + id, {
      method: "DELETE",
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {
      console.log(data)
      if(data !== null){

        AppDispatcher.dispatch({
          actionType: Constants.PLODUCT_DELETED
        })
      }else if(data === null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  update(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.PLODUCT_DETAIL_UPDATED,
      name:name,
      value:value
    })
  },
  updateList(name,value,id){
    AppDispatcher.dispatch({
      actionType: Constants.PLODUCT_DETAIL_UPDATED_LIST,
      name:name,
      value:value,
      id:id
    })
  },


  attachOnProduct(token,media_id,product_id,media_type){
    const item = {
      media_id: Number(media_id),
    	product_id: Number(product_id),
    	media_key:media_type
    }
    //console.log(item)

    fetch(config.get("API_PATH") + config.get("API_FILE_ATTACH").url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(item)
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null && data.status && data.status === 1){

        ProductActions.getDetail(token,product_id)

      }else if(data === null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },

  disAttachOnProduct(token,media_id,product_id,media_key){
    const item = {
      media_id: Number(media_id),
    	product_id: Number(product_id),
      media_key:media_key
    }


    fetch(config.get("API_PATH") + config.get("API_FILE_ATTACH").url, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      console.log(response)
      if(response.status === 204){
        return true
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data){
        ProductActions.getDetail(token,product_id)
      }else if(data == null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },
  setNew(params){
    AppDispatcher.dispatch({
      actionType: Constants.PLODUCT_DETAIL_LOADED,
      item:new Product({options:[]})
    })
  }






}

export default ProductActions
