import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Setting from "../vo/Setting"


var CHANGE_EVENT = "change" // chenge evnetを定数にする



var _list = []


var setList = (items) => {
  var __list = []
  for(var i in items){
    if(!items[i].parent_id || Number(items[i].parent_id) === 0){
      __list.push(SettingStore.createRecord(items[i]))
    }
  }
  _list = __list
}

var update = (name, value) => {
  for(var i in _list){
    if(_list[i].key_type === name){
      const item = _list[i]
      _list[i] = item.set("value",value)
    }
  }
}


var SettingStore = assign({},EventEmitter.prototype,{

  getList(){
    return _list
  },

  getDetail(name){

    for(var i in _list){
      if(_list[i].key_type === name){
        return(_list[i])
      }
    }
    const  setting = new Setting({key_type:name})
    _list.push(setting)
    return setting
  },

  getListByType(name){
    var list = []

    for(var i in _list){
      if(_list[i].key_type === name){
        list.push(_list[i])
      }
    }

    return list
  },
  getById(id){
    for(var i in _list) {
      const item = _list[i]
      if(item.id === id){
        return item
      }
    }
  },

  createRecord(item){

    return new Setting(
    {
      id:item.id,
      key_type:item.key_type,
      value:item.value
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.SETTING_LIST_LOADED:

      setList(action.list);
      SettingStore.emitChange()
      break;
    case Constants.SETTING_DETAIL_UPDATED:
      update(action.name , action.value)
      SettingStore.emitChange()
      break;



    default:


      // no
  }
})
export default SettingStore
