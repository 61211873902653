import React,{useState, useEffect,useCallback} from 'react'
import '../../styles/message_box.css'

function MessageBox(props:PropsValue) {


  const [className, setClassName] = useState(props.className)
  const stableTimeout = useCallback(closeHandler, [])



  useEffect(() => {
    if(props.className === "active"){
       (window.setTimeout(stableTimeout, 5000)) ;
    }
  },[props.className,stableTimeout]);

  function closeHandler(){
    const el = document.querySelector('.message-box-wrap');
    if(el){
      el.addEventListener('animationend', function() {
         props.close()
      });
    }
    setClassName("inactive")
  }


  return(
    <div className={"message-box-wrap " + className}>
      <div className={ props.error === 1 ? "message-box-header error" : "message-box-header"}>
        {props.title}
        <div className="close" onClick={closeHandler}><i className="fas fa-times"></i></div>
      </div>
      <div className="message-content">
        {props.message}
      </div>
    </div>
  )
}

export default MessageBox
