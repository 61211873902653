import React from "react"
import SettingActions from '../../actions/SettingActions'
import AuthStore from '../../stores/AuthStore'
import NoticeActions from '../../actions/NoticeActions'
import NoticeStore from '../../stores/NoticeStore'
import Input from './Input'
import Setting from '../../vo/Setting'

import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class NoticeEditor extends React.Component {

  constructor(){
    super()
    this.closeBtnHandler =this.closeBtnHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.noticeStoreChangeHandler = this.noticeStoreChangeHandler.bind(this)
    this.state = {
      item:null
    }
  }

  componentDidMount(){
    NoticeStore.addChangeListener(this.noticeStoreChangeHandler)
    NoticeActions.createNew(this.props.item)
  }


  componentWillUnmount(){
    NoticeStore.removeChangeListener(this.noticeStoreChangeHandler)
  }
  noticeStoreChangeHandler(){
    this.setState({
      item:NoticeStore.getDetail()
    })
  }
  changeValueHandler(name,value){
    NoticeActions.update(name,value)

  }
  closeBtnHandler(){
    this.setState({

    })
    this.props.closeBtnHandler()
  }

  clickHandler(){
    //console.log("save!")
    var key_type = null
    switch (this.props.type) {
      case "mail":
        key_type = "NOTICE_MAIL"
      break;
      case "slack":
        key_type = "NOTICE_SLACK"
      break;
      default:
    }
    SettingActions.updateExe(AuthStore.getToken(), new Setting(
      {
        id:this.props.id,
        key_type:key_type,
        value:JSON.stringify(this.state.item)
      }
    ))
      this.props.closeBtnHandler()
  }

  render(){

    //console.log(this.state.item)
    if(!this.state.item){
      return null
    }



    var _label = ""
    var _title = ""
    var _input = null

    switch (this.props.type) {
      case "mail":

        _label ="メールアドレス"
        _title = "メール通知設定"
        _input = <div>
                    <Input label={_label} type="mail" name="address"  changeValueHandler={this.changeValueHandler} value={this.state.item.address}/>
                    <Input label="通知項目"  type="checkbox" name="timing" value={this.state.item.timing} items={[{value:"order_created",title:config.get("NOTICE_order_created")},{value:"order_canceled",title:config.get("NOTICE_order_canceled")}]} changeValueHandler={this.changeValueHandler} />
                  </div>
        break;
      case "slack":
        _label ="Slack"
        _title = "Slack通知設定"
        _input = <div>
                    <Input label={_label} type="text" name="webhook"  changeValueHandler={this.changeValueHandler} value={this.state.item.webhook} placeholder={"http://xxx.slack.com/services/XXXXX"}/>
                    <Input label={_label} type="text" name="channel"  changeValueHandler={this.changeValueHandler} value={this.state.item.channel} placeholder={"#general"}/>
                    <Input label="通知項目"  type="checkbox" name="timing" value={this.state.item.timing} items={[{value:"order_created",title:config.get("NOTICE_order_created")},{value:"order_canceled",title:config.get("NOTICE_order_canceled")}]} changeValueHandler={this.changeValueHandler} />
                  </div>
        break;

      default:

    }


    return(
      <div class="over-flow-back">

      <div className="closebtn" onClick={this.closeBtnHandler}><i class="fas fa-times-circle"></i></div>

      <div className="over-flow-pannel">
        <section className="header-info">
          <h2>{_title}</h2>

        </section>
          {_input}

          <div className="btn-box">
            <button className="create-btn" onClick = {this.clickHandler}>保存</button>
          </div>
      </div>

    </div>
    )
  }
}
