import { Record } from 'immutable'

const Category = Record({
  id:null,
  name:null,
  parent_id:null,
  used_number:null,
  children:[]
});
export default Category;
