import React from "react"

import Header from "./parts/Header"
import AuthActions from '../actions/AuthActions';
import TradeActions from '../actions/TradeActions';
import AuthStore from '../stores/AuthStore'
import TradeStore from '../stores/TradeStore'
import ListTradeItem from './parts/ListTradeItem'


//import AuthStore from '../stores/AuthStore'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class DashBoardApp extends React.Component {

  constructor(){
    super()
    this.tradeStoreChangeHandler = this.tradeStoreChangeHandler.bind(this)
    this.pagingClikcHandler = this.pagingClikcHandler.bind(this)
    AuthActions.setAuth()

    this.state = {
      list:[]
    }
  }


  componentDidMount(){
    TradeStore.addChangeListener(this.tradeStoreChangeHandler)
    TradeActions.getList(AuthStore.getToken(),1)
  }


  componentWillUnmount(){
    TradeStore.removeChangeListener(this.tradeStoreChangeHandler)
  }


    tradeStoreChangeHandler(){
      this.setState({
        list:TradeStore.getList()
      })
    }

    pagingClikcHandler(id){
      const status = this.state.status ? (this.state.status + "/") :""
      window.location = "/shop/trade/search/" +  status + "?offset=" + id
    }

  render(){
    const listHtml = this.state.list.map((item) => {
      return <ListTradeItem item={item} key={"trade_" + item.id}/>
    })
    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-1">
            <section className="header-info">
              <h2>未発送取引一覧</h2>
            </section>

            <table className="list-table">
              <tbody>
                <tr><th>注文日時</th><th>注文者</th><th>購入商品</th><th>合計金額</th><th>支払い方法</th><th>支払い状況</th><th>ステータス</th></tr>
                {listHtml}
              </tbody>
            </table>
            </div>
          </main>
      </div>
    )
  }
}
