import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import MediaFile from "../vo/MediaFile"


var CHANGE_EVENT = "change" // chenge evnetを定数にする
var CHANGE_ADD_EVENT = "add_change" // chenge evnetを定数にする

var _item = new MediaFile()
var _list = []


var setList = (items) => {

  const __list = items.map((item) =>{
      return MediaStore.createRecord(item)
  })
  _list = __list
}
var addOnList = (item) => {
  _list.unshift(MediaStore.createRecord(item))
}


var MediaStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },
  getList(){
    return _list
  },


  createRecord(item){

    return new MediaFile(
    {
      id:item.id,
      bytes:item.size,
      file_name:item.file_name,
      url:item.url,
      media_type:item.media_type,
      media_key:item.media_key
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.FILE_LIST_LOADED:
      setList(action.list);
      MediaStore.emitChange()
    break;
    case Constants.FILE_ADDED:
      addOnList(action.item);
      MediaStore.emitChange(CHANGE_ADD_EVENT)
    break

    default:

  }
})
export default MediaStore
