import React from "react"

import Header from "./parts/Header"
import SideMenuSetting from './parts/SideMenuSetting'
import DeliveryActions from '../actions/DeliveryActions'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'
import DeliveryStore from '../stores/DeliveryStore'
import Input from './parts/Input'
import TopAlertBox  from './parts/TopAlertBox'
import Delivery from '../vo/Delivery'



import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class DeliveryDetailApp extends React.Component {

  constructor({match}){
    super()
    this.deliveryStoreChangeHandler = this.deliveryStoreChangeHandler.bind(this)
    this.deliveryUpdatedStoreChangeHandler = this.deliveryUpdatedStoreChangeHandler.bind(this)
    this.changeAllPriceHandler = this.changeAllPriceHandler.bind(this)
    this.clickAllHandler = this.clickAllHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.closeBtnHandler =this.closeBtnHandler.bind(this)
    AuthActions.setAuth()
    this.state = {
      id:Number(match.params.id),
      item:null,
      all_price:null,
      display_message:false,
      message:null
    }
  }

  componentDidMount(){
    DeliveryStore.addChangeListener(this.deliveryStoreChangeHandler)
    DeliveryStore.addChangeListener(this.deliveryUpdatedStoreChangeHandler,"delivery_updated")
    if(this.state.id && this.state.id !== 0){
      DeliveryActions.getDetail(AuthStore.getToken(),this.state.id)
    }else{
      this.setState({
        item:new Delivery({
          country:"jp"
        })
      })
    }

    //ShopStore.addChangeListener(this.shopStoreChangeHandler)
  //
  }


  componentWillUnmount(){
    DeliveryStore.removeChangeListener(this.deliveryStoreChangeHandler)
    DeliveryStore.removeChangeListener(this.deliveryUpdatedStoreChangeHandler,"delivery_updated")
    //ShopStore.removeChangeListener(this.shopStoreChangeHandler)

  }
  deliveryStoreChangeHandler(){

    this.setState({
      item:DeliveryStore.getDetail()
    })
  }
  deliveryUpdatedStoreChangeHandler(){
    this.setState({
      item:DeliveryStore.getDetail(),
      display_message:true,
      message:"保存されました。"
    })
  }
  changeValueHandler(name,value){
    DeliveryActions.update(name, value)
  }
  changeArePriceHandler(name,value){
    DeliveryActions.updateAreaPrice(name,value)
  }
  changeAllPriceHandler(name,value){
    this.setState({
      all_price:value
    })
  }
  clickAllHandler(){
    for(var i = 1; i <= 47; i++){
      DeliveryActions.updateAreaPrice("area_" + i,this.state.all_price)
    }
  }
  clickHandler(){
    this.setState({
      display_message:false
    })
    DeliveryActions.updateExe(AuthStore.getToken(), this.state.item)
  }
  clickBackHandler(){
    window.location = "/shop/settings/delivery"
  }
  closeBtnHandler(){
    this.setState({
      display_message:false
    })
  }
  selectTypeChangeHandler(e){
    const target = e.target
    DeliveryActions.update(target.name, Number(target.value))
  }

  getAreaValue(id){
    for(var i in this.state.item.price ){
      const item = this.state.item.price[i]
      if( Number(item.id) === Number(id)){
        return item.price
      }
    }
  }


  render(){
    if(!this.state.item) return null
    // changeValueHandler={this.changeArePriceHandler(i)}
    var hokkaido_html = []
    var i = 0
    for(i = 1; i < 2; i++){
       hokkaido_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }
    var tohoku_html = []
    for( i = 2; i <= 7; i++){
       tohoku_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }
    var kanto_html = []
    for( i = 8; i <= 14; i++){
       kanto_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }
    var hokuriku_html = []
    for( i = 15; i <= 18; i++){
       hokuriku_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }
    var koshin_html = []
    for( i = 19; i <= 20; i++){
       koshin_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }
    var tokai_html = []
    for( i = 21; i <= 24; i++){
       tokai_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }

    var kinki_html = []
    for(i = 25; i <= 30; i++){
       kinki_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }

    var chugoku_html = []
    for(i = 31; i <= 35; i++){
       chugoku_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }

    var shikoku_html = []
    for(i = 36; i <= 39; i++){
       shikoku_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }

    var kyusyu_html = []
    for(i = 40; i <= 46; i++){
       kyusyu_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }
    var okinawa_html = []
    for(i = 47; i <= 47; i++){
       okinawa_html.push(<Input label={config.get("PREF_" + i)} type="number" divclass="small-1" name={"area_" + i}  value={this.getAreaValue(i)} changeValueHandler={this.changeArePriceHandler}/>)
    }
    //console.log(this.state.display_message)
    const addclass = this.state.display_message ? "in" : null

    return(
      <div>
        <TopAlertBox text={this.state.message} addclass={addclass} closeBtnHandler={this.closeBtnHandler}/>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <SideMenuSetting id="main" />
              </section>
              <section className="main-2-pannel">

                <section className="header-info">
                  <h2>配送設定</h2>
                </section>
                <Input label="名称" type="text" name="name" value={this.state.item.name} changeValueHandler={this.changeValueHandler} />
                <div className="input-box">
                  <label>商品タイプ</label>
                  <select name="product_type" value={this.state.item.product_type} onChange={this.selectTypeChangeHandler}>
                    <option value="0">-</option>
                    <option value="1">{config.get("PRODUCT_TYPE_1")}</option>
                    <option value="2">{config.get("PRODUCT_TYPE_2")}</option>
                  </select>
                </div>
                <div className="input-box">
                  <label>エリア</label>
                  <select name="parent_id" value={this.state.item.country} onChange={this.selectAreaChangeHandler}>
                    <option value="jp">{config.get("COUNTRY_jp")}</option>
                  </select>
                </div>
                <Input label="送料加算（個数が増えた場合送料を加算する）" type="checkbox" name="add_on" value={[this.state.item.add_on]} items={[{value:1,title:"加算する"}]} changeValueHandler={this.changeValueHandler} />
                <Input label="送料無料条件（金額）" type="number" name="free_ship_price" value={this.state.item.free_ship_price} changeValueHandler={this.changeValueHandler} placeholder={10000}/>
                <div className="input-box">
                  <label>金額設定</label>
                  <div className="price-area">
                    <Input label="全ての県に設定" type="number" divclass="small-1" name="all_class"  value={this.state.all_price} changeValueHandler={this.changeAllPriceHandler}/>
                    <div className="btn-box">
                      <button className="create-btn small" onClick = {this.clickAllHandler}>設定</button>
                    </div>
                  </div>

                  <div className="price-area">
                    {hokkaido_html}
                  </div>
                  <div className="price-area">
                    {tohoku_html}
                  </div>
                  <div className="price-area">
                    {kanto_html}
                  </div>

                  <div className="price-area">
                    {hokuriku_html}
                  </div>
                  <div className="price-area">
                    {koshin_html}
                  </div>
                  <div className="price-area">
                    {tokai_html}
                  </div>
                  <div className="price-area">
                    {kinki_html}
                  </div>
                  <div className="price-area">
                    {chugoku_html}
                  </div>
                  <div className="price-area">
                    {shikoku_html}
                  </div>
                  <div className="price-area">
                    {kyusyu_html}
                  </div>
                  <div className="price-area noboarder">
                    {okinawa_html}
                  </div>

                </div>

                <div className="btn-box">
                    <button className="white-btn midium white" onClick = {this.clickBackHandler}>戻る</button>
                  <button className="create-btn midium" onClick = {this.clickHandler}>保存</button>
                </div>

              </section>
            </div>
          </main>
      </div>
    )
  }
}
