import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';


var ShopActions = {


  getDetail(token){
    console.log(token)
    fetch(config.get("SHOP_API_PATH") + config.get("API_SHOP_DETAIL").url, {
      method: config.get("API_SHOP_DETAIL").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {
      console.log(data)
      if(data !== null ){

        AppDispatcher.dispatch({
          actionType: Constants.SHOP_DETAIL_LOADED,
          item:data
        })
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  updateExe(token,item){

    fetch(config.get("SHOP_API_PATH") + config.get("API_SHOP_UPDATE").url, {
      method: config.get("API_SHOP_UPDATE").method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null && data.status && data.status === 1){

        AppDispatcher.dispatch({
          actionType: Constants.SHOP_DETAIL_LOADED,
          item:data.shop
        })
      }else if(data === null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },

  update(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.SHOP_DETAIL_UPDATED,
      name:name,
      value:value
    })
  },







}

export default ShopActions
