import React from "react"
import {  Link } from 'react-router-dom'
import Header from "./parts/Header"
import MediaActions from '../actions/MediaActions';
import AuthStore from '../stores/AuthStore'
import MediaStore from '../stores/MediaStore'
import FileUploader from './parts/FileUploader'
import ListMediaItem from './parts/ListMediaItem'
import MediaDetail from './parts/MediaDetail'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class MediaApp extends React.Component {

  constructor(){
    super()
    this.mediaStoreChangeHandler = this.mediaStoreChangeHandler.bind(this)
    this.displayUploadFile = this.displayUploadFile.bind(this)
    this.closeBtnHandler =this.closeBtnHandler.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    this.mediaStoreAddChangeHandler = this.mediaStoreAddChangeHandler.bind(this)
    this.onClickHandler = this.onClickHandler.bind(this)
    this.deleteFile = this.deleteFile.bind(this)
    this.state = {
      list:[],
      upload:false
    }
  }

  componentDidMount(){
    MediaStore.addChangeListener(this.mediaStoreChangeHandler)
    MediaStore.addChangeListener(this.mediaStoreAddChangeHandler,"add_change")

    MediaActions.getList(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberListChangeHandler)
    //MemberActions.getMemberList(AuthStore.getToken())

  }


  componentWillUnmount(){
    MediaStore.removeChangeListener(this.mediaStoreChangeHandler)
    MediaStore.removeChangeListener(this.mediaStoreAddChangeHandler,"add_change")
  }

  mediaStoreChangeHandler(){
    this.setState({
      list:MediaStore.getList().reverse()
    })
  }
  mediaStoreAddChangeHandler(){
    this.setState({
      list:MediaStore.getList(),
      upload:false,
      detail:false,
      detail_item:null
    })
  }

  deleteFile(file_name){
    this.setState({
      detail:false,
      detail_item:null
    })
    MediaActions.deleteFile(AuthStore.getToken(), file_name)
  }

  displayUploadFile(e){
    e.preventDefault()
    this.setState({
      upload:true
    })
  }
  closeBtnHandler(){
    this.setState({
      upload:false,
      detail:false
    })
  }
  uploadFile(file){
    MediaActions.uploadFile(AuthStore.getToken(),file )
  }
  onClickHandler(item){
    this.setState({
      detail:true,
      detail_item:item
    })
  }
  render(){

    const listHtml = this.state.list.length === 0 ? <span className="sml">ファイルが存在しません。</span> : this.state.list.map((item) => {
      if(item.media_type === "image/jpeg" || item.media_type === "image/png")
        return <ListMediaItem item={item} onClickHandler={this.onClickHandler}/>
      else return null
    })


    return(
      <div>
      <MediaDetail is_display = {this.state.detail} closeBtnHandler={this.closeBtnHandler} item={this.state.detail_item} deleteFile={this.deleteFile}/>
      <FileUploader is_display={this.state.upload} closeBtnHandler={this.closeBtnHandler} uploadFile={this.uploadFile}/>

        <Header />

          <main>
            <div className="main-pannel-1">
              <section className="header-info">
                <div className="head-right">
                  <Link to="#" onClick={this.displayUploadFile}><i class="fas fa-plus-circle"></i> 新規ファイル</Link>
                </div>
              </section>

                <ul className="media-list">
                  {listHtml}
                </ul>

            </div>
          </main>
      </div>
    )
  }
}
