import React from "react"

import Header from "./parts/Header"
import TradeActions from '../actions/TradeActions'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'
import TradeStore from '../stores/TradeStore'

import TopAlertBox  from './parts/TopAlertBox'
import PaymentEditor  from './parts/PaymentEditor'
import common from '../utiles/Common'




import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class TradeDetailApp extends React.Component {

  constructor({match}){
    super()
    this.tradeStoreChangeHandler = this.tradeStoreChangeHandler.bind(this)
    this.updateBtnClickHandler = this.updateBtnClickHandler.bind(this)
    this.closeBtnHandler = this.closeBtnHandler.bind(this)
    this.displayPayRecord = this.displayPayRecord.bind(this)
    this.clickBackHandler = this.clickBackHandler.bind(this)

    AuthActions.setAuth()
    this.state = {
      id:match.params.id,
      item:null,
      display_message:false,
      message:null,
      is_saving:false,
      display_payment:false
    }
  }

  componentDidMount(){
    TradeStore.addChangeListener(this.tradeStoreChangeHandler)

    if(this.state.id && this.state.id !== 0){
      TradeActions.getDetail(AuthStore.getToken(),this.state.id)
    }

    //ShopStore.addChangeListener(this.shopStoreChangeHandler)
  //
  }


  componentWillUnmount(){
    TradeStore.removeChangeListener(this.tradeStoreChangeHandler)

  }
  tradeStoreChangeHandler(){
    if(this.state.is_saving){
      this.tradeUpdatedStoreChangeHandler()
    }else{
      this.setState({
        item:TradeStore.getDetail()
      })
    }

  }
  tradeUpdatedStoreChangeHandler(){
    this.setState({
      item:TradeStore.getDetail(),
      display_message:true,
      message:"保存されました。",
      is_saving: false
    })
  }
  changeValueHandler(name,value){
    TradeActions.update(name, value)
  }

  statusChangeHandler(e){
    const target = e.target
    TradeActions.update("status", target.value)
  }

  updateBtnClickHandler(){
    this.setState({
      is_saving: true
    })
    TradeActions.updateExe(AuthStore.getToken(), this.state.id, this.state.item.status)
  }

  clickBackHandler(){
    if(this.props.history){
      this.props.history.goBack()
    }else{
      window.location = "/shop/trade"
    }

  }
  closeBtnHandler(){
    this.setState({
      display_message:false,
      display_payment:false
    })
  }
  displayPayRecord(){
    this.setState({
      display_payment:true
    })
  }




  render(){
    if(!this.state.item) return null
    const customer_info = this.state.item.customer_info ?  JSON.parse(this.state.item.customer_info) : null
    const shipping = this.state.item.shipping_info ? JSON.parse(this.state.item.shipping_info) : null
    const name = shipping ? shipping.name : "-"
    const postcode = shipping ? shipping.postcode : "-"
    const address = shipping ? shipping.address : "-"
    const tel = shipping ? shipping.tel : "-"
    const mail = shipping ? shipping.mail : "-"

    const products_html = this.state.item.products ? this.state.item.products.map((item) => {
        const product_sub = (item.x_name || item.y_name ) ? <span>({item.x_name} {item.y_name})</span>: null
        const item_html = item.product ? <div key={"product_" + item.id}>{item.product.title} {product_sub}</div> : null
        return item_html
    }) : null

    const payment_html = this.state.item.payment_method ? this.state.item.payment_method.name : null
    const paid = this.state.item.payments.length > 0 ? "支払い済み" : "未入金"
    const status = this.state.item.status ? this.state.item.status : 0
    const addclass = this.state.display_message ? "in" : null
    const delivery_html = this.state.item.delivery_method ? this.state.item.delivery_method.name : null

    const products_detail_html = this.state.item.products ? this.state.item.products.map((item) => {
        const item_name_html = item.product ? <tr><th>商品名</th><td>{item.product.title}</td></tr> : null
        const x_html = item.product ? <tr><th>種類</th><td>{item.x_name} {item.y_name}</td></tr> : null
        const q_html =  <tr><th>数量</th><td>{item.quantity}</td></tr>

        return <table className="list-table detail" key={"product_" + item.id}><tbody>{item_name_html}{x_html}{q_html}</tbody></table>
    }):null

    const paid_html = this.state.item.payments.map((item) => {
      return <table className="list-table detail" key={"pay_" + item.id}>
        <tbody><tr><th>支払い方法</th><td>{config.get("PAYMENT_TYPE_" + item.pay_type)}</td></tr>
        <tr><th>支払い金額</th><td>{item.amount.toLocaleString()}円</td></tr>
        <tr><th>支払い日</th><td>{common.getDateTimeJpString(item.created_at)}</td></tr>
        </tbody>
      </table>
    })

    const product_total = this.state.item.product_total_price ? <span>{this.state.item.product_total_price.toLocaleString()}円</span>  : ""
    const tax = this.state.item.tax ? <span>{this.state.item.tax.toLocaleString()}円</span>  : ""
    const delivery_price = this.state.item.delivery_price ? <span>{this.state.item.delivery_price.toLocaleString()}円</span>  : ""
    const display_payment = this.state.display_payment ? <PaymentEditor closeBtnHandler={this.closeBtnHandler} trade_id={this.state.id} /> : null

    return(
      <div>
        <TopAlertBox text={this.state.message} addclass={addclass} closeBtnHandler={this.closeBtnHandler}/>
        {display_payment}
        <Header />
          <main>
            <div className="main-pannel-1">

                <section className="header-info">
                  <h2>取引情報詳細</h2>
                </section>

                <table className="list-table detail">

                  <tbody>
                    <tr><th>注文日時</th><td>{common.getDateTimeJpString(this.state.item.created_at)}</td></tr>
                    <tr><th>注文者</th><td>{customer_info ? customer_info.name : null}</td></tr>
                    <tr><th>Mail</th><td>{customer_info ? customer_info.mail : null}</td></tr>
                    <tr><th>購入商品</th><td>{products_html}</td></tr>
                    <tr><th>合計金額</th><td>{this.state.item.total_price.toLocaleString()}円</td></tr>
                    <tr><th>支払い方法</th><td>{payment_html}</td></tr>

                    <tr><th>支払い状況</th><td>{paid}</td></tr>
                    <tr><th>配送方法</th><td>{delivery_html}</td></tr>
                    <tr><th>ステータス</th><td>
                    <div className="input-box innerbox">
                      <label>ステータス</label>
                      <select name="parent_id" value={status} onChange={this.statusChangeHandler}>
                        <option value="0">-</option>
                        <option value="1">{config.get("STATUS_1")}</option>
                        <option value="10">{config.get("STATUS_10")}</option>
                        <option value="20">{config.get("STATUS_20")}</option>
                      </select>
                    </div>
                    </td></tr>
                  </tbody>
                </table>

                <section className="header-info">
                  <h2>注文商品情報</h2>
                </section>
                {products_detail_html}

                <section className="header-info">
                  <h2>注文金額情報</h2>
                </section>
                <table className="list-table detail">
                  <tbody>
                    <tr><th>商品金額</th><td>{product_total}</td></tr>
                    <tr><th>税金</th><td>{tax}</td></tr>
                    <tr><th>送料</th><td>{delivery_price}</td></tr>
                    <tr><th>合計</th><td>{this.state.item.total_price.toLocaleString()}円</td></tr>
                  </tbody>
                </table>
                <section className="header-info">
                  <h2>支払い履歴</h2>
                  <div className="head-right">
                    <div className="link" onClick={this.displayPayRecord}><i className="fas fa-plus-circle"></i>支払い履歴登録</div>
                  </div>
                </section>

                    {paid_html}


                <section className="header-info">
                  <h2>配送情報</h2>
                </section>
                <table className="list-table detail">
                  <tbody>
                    <tr><th>配送先</th><td>{name}</td></tr>
                    <tr><th>住所</th><td>〒{postcode}　 {address}</td></tr>
                    <tr><th>TEL</th><td>{tel}</td></tr>
                    <tr><th>E-mail</th><td>{mail}</td></tr>
                  </tbody>
                </table>

                <section className="header-info">
                  <h2>コメント</h2>
                </section>
                <table className="list-table detail">
                  <tbody>
                    <tr><td>{this.state.item.comment}</td></tr>
                  </tbody>
                </table>



                <div className="btn-box">
                    <button className="white-btn midium white" onClick = {this.clickBackHandler}>戻る</button>
                  <button className="create-btn midium" onClick = {this.updateBtnClickHandler}>保存</button>
                </div>

            </div>
          </main>
      </div>
    )
  }
}
