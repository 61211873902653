import React from "react"

import Header from "./parts/Header"
import AuthActions from '../actions/AuthActions'
import CategoryActions from '../actions/CategoryActions';
import AuthStore from '../stores/AuthStore'
import CategoryStore from '../stores/CategoryStore'
import Category from '../vo/Category'
import Input from './parts/Input'


/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class CategoryDetailApp extends React.Component {

  constructor({match}){
    super()
    this.categoryStoreChangeHandler = this.categoryStoreChangeHandler.bind(this)
    this.changeValueHandler = this.changeValueHandler.bind(this)
    this.selectChangeHandler = this.selectChangeHandler.bind(this)
    this.categoryStoreUpdateHandler = this.categoryStoreUpdateHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    AuthActions.setAuth()
    this.state = {
      item:Category(),
      id:match.params.id,
      list:[]
    }
  }

  componentDidMount(){
    CategoryStore.addChangeListener(this.categoryStoreChangeHandler)
    CategoryStore.addChangeListener(this.categoryStoreUpdateHandler,"update")
    if(this.state.id !== 0){
        CategoryActions.getList(AuthStore.getToken())
        CategoryActions.getDetail(AuthStore.getToken(),this.state.id)
    }

  }
  componentWillUnmount(){
    CategoryStore.removeChangeListener(this.categoryStoreChangeHandler)
    CategoryStore.addChangeListener(this.categoryStoreUpdateHandler,"update")
  }


  categoryStoreChangeHandler(){
    this.setState({
      list:CategoryStore.getList(),
      item:CategoryStore.getDetail()
    })
  }
  categoryStoreUpdateHandler(){
    this.setState({
      item:CategoryStore.getDetail()
    })
  }
  selectChangeHandler(e){
    const target=e.target
    var parent_id = null
    if(target.value !== 0){
      parent_id = Number(target.value)
    }
    CategoryActions.update("parent_id",parent_id)
  }

  changeValueHandler(name,value){
    CategoryActions.update(name,value)
  }
  clickHandler(){
    CategoryActions.updateExe(AuthStore.getToken(), this.state.item)
  }
  getInputItem(item,step){
    var spacer = ""
    for(var i = 0; i < step; i++){
      spacer = spacer + "　"
    }
    spacer = spacer + "|-"
    var items = []
    items.push(<option value={item.id} key={"opt_" + item.id}>{spacer + item.name}</option> )
    for(let k in item.children){
      const _item = item.children[k]
      if(_item){
        items.push(this.getInputItem(_item, step+1))
      }
    }
    
    return items

  }


  render(){


    if(this.state.item == null) return null

    const parents = this.state.list.map((item) => {
      return this.getInputItem(item,0)
    })


    const category_id = this.state.item.parent_id ? this.state.item.parent_id : null

    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-2">
              <section className="left-pannel">
                <Input label="名称" type="text" name="name" value={this.state.item.name} changeValueHandler={this.changeValueHandler} />
                <div className="input-box">
                  <label>親カテゴリー</label>
                  <select name="parent_id" value={category_id} onChange={this.selectChangeHandler}>
                    <option value="0">-</option>
                    {parents}
                  </select>
                </div>

                <div className="btn-box">
                  <button className="create-btn" onClick = {this.clickHandler}>保存</button>
                </div>

              </section>
              <section className="right-pannel">

                <div className="status-box">
                  <div className="status-label">カテゴリーID</div>
                  <div className="status-content">{this.state.item.id}</div>
                </div>
              </section>

            </div>
          </main>
      </div>
    )
  }
}
