import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';


var PaymentActions = {

  getList(token){
    fetch(config.get("API_PATH") + config.get("API_PAYMENT_LIST").url, {
      method: config.get("API_PAYMENT_LIST").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {
      //console.log(data)
      if(data !== null && data.status && data.status === 1){

        AppDispatcher.dispatch({
          actionType: Constants.PAYMENT_LIST_LOADED,
          list:data.payments
        })
      }else if(data == null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  updateExe(token,item){

    var data = {
      method_type:item.method_type,
      name:item.name,
      setting:{}
    }
    //ID
    if(item.id){
      data.id =item.id
    }
    //PAY_JP
    if(item.setting.payjp && item.setting.payjp.key){
      data.setting.payjp = {key:item.setting.payjp.key}
    }

    //PAYPAL
    if(item.setting.paypal && item.setting.paypal.client_id && item.setting.paypal.secret){
      data.setting.paypal = {client_id:item.setting.paypal.client_id, secret:item.setting.paypal.secret}
    }

    //Bank
    if(item.setting.amazon && item.setting.amazon.client_id && item.setting.amazon.secret){
      data.setting.amazon = {client_id:item.setting.amazon.client_id, secret:item.setting.amazon.secret}
    }

    //Bank
    if(item.setting.bank ){
      data.setting.bank = item.setting.bank
    }


    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_PAYMENT_UPDATE").url + "/" + item.id : config.get("API_PATH") + config.get("API_PAYMENT_UPDATE").url
    fetch(url, {
      method: method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){
        AppDispatcher.dispatch({
          actionType: Constants.PAYMENT_UPDATED,
          item:data
        })
      }else if(data == null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  delete(token,id){
    const item = {id:id}
    fetch(config.get("API_PATH") + config.get("API_PAYMENT_DELETE").url, {
      method: config.get("API_PAYMENT_DELETE").method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null && data.status && data.status === 1){
        PaymentActions.getList(token)
      }else if(data == null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  getDetail(token,id){
    fetch(config.get("API_PATH") + config.get("API_PAYMENT_DETAIL").url + "/" + id, {
      method: config.get("API_PAYMENT_DETAIL").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null && data.status && data.status === 1){
        AppDispatcher.dispatch({
          actionType: Constants.PAYMENT_DETAIL_LOADED,
          item:data.payment
        })
      }else if(data == null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },

  update(name,value,isin=false){
    AppDispatcher.dispatch({
      actionType: Constants.PAYMENT_UPDATE,
      name:name,
      value:value,
      isin:isin
    })
  }
}

export default PaymentActions
