import React from "react"

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class Header extends React.Component {

  constructor(){
    super()
    //AuthActions.setAuth()
    this.state = {

    }
  }

  componentDidMount(){
    //console.log(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberStoreChangeHandler)
    //MemberActions.getDetail(AuthStore.getToken())

  }

  componentWillUnmount(){
      //MemberStore.removeChangeListener(this.memberStoreChangeHandler)
  }

  /* Handlers */

  memberStoreChangeHandler(){


  }

  render(){


    return(
      <ul>
        <li><a href="/shop/settings"><i className="fas fa-store-alt"></i>メイン設定</a></li>
        <li><a href="/shop/settings/delivery"><i className="fas fa-truck"></i>配送</a></li>
        <li><a href="/shop/settings/payment"><i className="fas fa-credit-card"></i>支払い方法</a></li>
        <li><a href="/shop/settings/template/mail"><i className="fas fa-envelope"></i>メールテンプレート</a></li>
        <li><a href="/shop/settings/tax"><i className="fas fa-file-invoice-dollar"></i>消費税</a></li>
        <li><a href="/shop/settings/notice"><i className="fas fa-exclamation"></i>通知</a></li>
        
      </ul>

    )
  }
}
