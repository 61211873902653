import React from "react"



export default class TopAlertBox extends React.Component {

  /*constructor(){
    super()
  }*/

  componentDidMount(){
  }

  componentWillUnmount(){
  }




  render(){

    return(
      <div className={"alert-top-box-wrap " + this.props.addclass}>
        <div id="alert-box" className="alert-top">
          <div className="closebtn-white" onClick={this.props.closeBtnHandler}><i className="fas fa-times"></i></div>
          {this.props.text}
        </div>
      </div>
    )
  }
}
