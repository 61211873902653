import React from "react"

import AlertBox from './AlertBox'


/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class MediaDetail extends React.Component {

  constructor(){
    super()
    this.clickHandler = this.clickHandler.bind(this)
    this.closeBtnHandler = this.closeBtnHandler.bind(this)
    this.cancelHandler = this.cancelHandler.bind(this)
    this.deleteExe = this.deleteExe.bind(this)

    this.state = {
      file:null,
      image_src:null,
      message:null,
      confirm:false,
      processing:false
    }
  }

  componentDidMount(){

    //console.log(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberStoreChangeHandler)
    //MemberActions.getDetail(AuthStore.getToken())

  }

  componentWillUnmount(){
      //MemberStore.removeChangeListener(this.memberStoreChangeHandler)
  }

  /* Handlers */

  memberStoreChangeHandler(){
  }

  closeBtnHandler(){
    this.setState({

    })
    this.props.closeBtnHandler()
  }

  clickHandler(){
    if(this.props.type === "select"){
      this.props.selectedItem()
    }else{
      this.setState({
        confirm:true
      })
    }
  }
  cancelHandler(){
    this.setState({
      confirm:false
    })
  }

  deleteExe(){
    this.setState({
      confirm:false
    })
    this.props.deleteFile(this.props.item.file_name)
  }



  render(){

    if(!this.props.is_display){
      return null
    }

    var sizeHtml = null
    var sizenum = this.props.item.bytes
    if(sizenum < 1024){
      sizeHtml = Math.round(sizenum) + "B"
    }else if(sizenum / 1024 < 1024 ){
      sizeHtml = Math.round(sizenum / 1024) + "K"
    }else{
      sizeHtml = Math.round(sizenum / (1024 * 1024)) + "M"
    }

    const confirmHtml = this.state.confirm ? <AlertBox cancel={"キャンセル"} ok={"削除する"} okHandler={this.deleteExe} cancelHandler={this.cancelHandler}/> : null
    const deleteBtnHtml = this.state.processing ? <i class="fas fa-spinner fa-spin"></i> : <button className="create-btn" onClick = {this.clickHandler}>削除</button>
    const seectBtnHtml = this.state.processing ? <i class="fas fa-spinner fa-spin"></i> : <button className="create-btn" onClick = {this.clickHandler}>選択</button>
    var  btnHtml = null
    if(this.props.type === "select"){
      btnHtml = seectBtnHtml
    }else{
      btnHtml = deleteBtnHtml
    }
    //const image_item = this.getMainImage()
    //var mainimage = image_item ? <img src={image_item.url} /> : <div className="link" nClick={this.updateMainImage}><i class="far fa-image"></i> メイン画像を設定</div>

    return(
      <div class="over-flow-back">
        {confirmHtml}
        <div className="main-pannel-2">
          <div className="closebtn" onClick={this.closeBtnHandler}><i class="fas fa-times-circle"></i></div>
          <section className="left-pannel">
            <div className="media-detail-file">
              <img src={this.props.item.url} alt="detail"/>
            </div>
          </section>
          <section className="right-pannel">
          <div className="status-box">
            <div className="status-label">ファイル名</div>
            <div className="status-content">{this.props.item.file_name}</div>

            <div className="status-label">サイズ</div>
            <div className="status-content">{sizeHtml}</div>

            <div className="btn-box">
              {btnHtml}
            </div>



          </div>
          </section>

        </div>
      </div>
    )
  }
}
