import React from "react"

import Header from "./parts/Header"
import SideMenuSetting from './parts/SideMenuSetting'
import MailTemplateActions from '../actions/MailTemplateActions'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'
import MailTemplateStore from '../stores/MailTemplateStore'


import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class NoticeApp extends React.Component {

  constructor(){
    super()
    this.mailTemplateStoreChangeHandler = this.mailTemplateStoreChangeHandler.bind(this)
    AuthActions.setAuth()
    this.state = {
      list:[]
    }
  }

  componentDidMount(){
    MailTemplateStore.addChangeListener(this.mailTemplateStoreChangeHandler)
    //ShopStore.addChangeListener(this.shopStoreChangeHandler)
    MailTemplateActions.getList(AuthStore.getToken())
  }


  componentWillUnmount(){
    MailTemplateStore.removeChangeListener(this.mailTemplateStoreChangeHandler)
    //ShopStore.removeChangeListener(this.shopStoreChangeHandler)

  }
  mailTemplateStoreChangeHandler(){

    this.setState({
      list:MailTemplateStore.getList()
    })
  }
  getByType(type){
    for(var i in this.state.list){
      const item = this.state.list[i]

      if(item.send_type === type) {
        return item
      }
    }
    return null
  }
  getTitle(type){
    return  this.getByType(type) ? this.getByType(type).title : "未設定"　
  }

  editBthHandler(id){
    //event.preventDefault();
    window.location = "/shop/settings/template/mail/" + id
  }

  render(){

  //  if(this.state.list.length == 0 ) return null


    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <SideMenuSetting id="main" />
              </section>
              <section className="main-2-pannel">
                <section className="header-info">
                  <h2>メールテンプレート一覧</h2>
                </section>
                <ul className="list-type1">
                  <li><div className="list-title">{config.get("NOTICE_order_created")}</div><div >{this.getTitle("order_created")}</div><div className="linkbtns"><span className="link" onClick={()=>this.editBthHandler("order_created")}><i className="fas fa-edit"></i> 編集</span></div></li>
                  <li><div className="list-title">{config.get("NOTICE_order_canceled")}</div><div >{this.getTitle("order_canceled")}</div><div className="linkbtns"><span className="link" onClick={()=>this.editBthHandler("order_canceled")}><i className="fas fa-edit"></i> 編集</span></div></li>
                  <li><div className="list-title">{config.get("NOTICE_paid")}</div><div >{this.getTitle("paid")}</div><div className="linkbtns"><span className="link" onClick={()=>this.editBthHandler("paid")}><i className="fas fa-edit"></i> 編集</span></div></li>
                  <li><div className="list-title">{config.get("NOTICE_delivered")}</div><div >{this.getTitle("delivered")}</div><div className="linkbtns"><span className="link" onClick={()=>this.editBthHandler("delivered")}><i className="fas fa-edit"></i> 編集</span></div></li>
                  <li><div className="list-title">{config.get("NOTICE_question")}</div><div >{this.getTitle("question")}</div><div className="linkbtns"><span className="link" onClick={()=>this.editBthHandler("question")}><i className="fas fa-edit"></i> 編集</span></div></li>

                </ul>
              </section>
            </div>
          </main>
      </div>
    )
  }
}
