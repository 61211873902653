import { Record } from 'immutable'

const MediaFile = Record({
  id:null,
  bytes:null,
  file_name: null,
  url: null,
  media_type: null,
  media_key: null
});
export default MediaFile;
