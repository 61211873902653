import React from "react"

import {Link } from 'react-router-dom'
import AuthStore from '../../stores/AuthStore'
import CategoryActions from '../../actions/CategoryActions'


/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class CategoryItem extends React.Component {

  constructor(){
    super()
    this.deleteBtnHandlere = this.deleteBtnHandlere.bind(this)
    this.parentCountUp = this.parentCountUp.bind(this)
    this.getTotalNum = this.getTotalNum.bind(this)
    this.state = {
      closeBtn:false
    }
  }

  componentDidMount(){

    this.setState({
      used_number:this.props.item.used_number
    })

    if(this.props.item.children.size === 0){
      //this.parentCountUp(this.props.item.used_number)
    }
  }

  componentWillUnmount(){

  }
  deleteBtnHandlere(){
    CategoryActions.delete(AuthStore.getToken(), this.props.item.id)
  }
  /* Handlers */


  parentCountUp(count){
    //console.log(this.state.item.name)
    if(this.props.item.children.size !== 0){
      this.setState({
        used_number:(this.state.used_number + count)
      })
    }

    if(this.props.parentCountUp){
      this.props.parentCountUp(this.state.used_number + count)
    }
  }
  getChildTotal(items){
    var total_number = 0
    for(var i = 0; i < items.size ; i++){
      total_number += items.get(i).used_number
      total_number += this.getChildTotal(items.get(i).children)
    }
    return total_number

  }

  getTotalNum(){
    return this.props.item.used_number + this.getChildTotal(this.props.item.children)
  }



  render(){
    //console.log(this.state.used_number)
    const delete_html = this.props.item.children.size > 0 ? null : <span className="link" onClick={this.deleteBtnHandlere}><i className="fas fa-minus-circle"></i> 削除</span>
    const children_html =　!this.props.item.children ? null : this.props.item.children.map((item) => {
      return <CategoryItem item={item} key={"category_" + item.id} parentCountUp={this.parentCountUp}/>
    })



    return(
      <div onClick={this.onClickHandler} className="category-item">
        {this.props.item.name}&nbsp;({this.getTotalNum()}) &nbsp; <Link className="link" to={"/shop/category/" + this.props.item.id}><i class="fas fa-edit"></i> 編集</Link> &nbsp; {delete_html}
        {children_html}
      </div>
    )
  }
}
