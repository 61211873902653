import React from "react"

import Header from "./parts/Header"
import SideMenuSetting from './parts/SideMenuSetting'
import PaymentActions from '../actions/PaymentActions'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'
import PaymentStore from '../stores/PaymentStore'
import Input from './parts/Input'
import TopAlertBox  from './parts/TopAlertBox'
import Payment from '../vo/Payment'



import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class PaymentDetailApp extends React.Component {

  constructor({match}){
    super()
    this.paymentStoreChangeHandler = this.paymentStoreChangeHandler.bind(this)
    this.paymentUpdatedStoreChangeHandler = this.paymentUpdatedStoreChangeHandler.bind(this)

    this.clickHandler = this.clickHandler.bind(this)
    this.closeBtnHandler =this.closeBtnHandler.bind(this)

    AuthActions.setAuth()
    this.id = match.params.id
    this.state = {
      item:null,
      display_message:false,
      message:null
    }
  }

  componentDidMount(){
    PaymentStore.addChangeListener(this.paymentStoreChangeHandler)
    PaymentStore.addChangeListener(this.paymentUpdatedStoreChangeHandler,"updated")
    if(this.id && this.id !== 0 && this.id !== "new"){
      PaymentActions.getDetail(AuthStore.getToken(),this.id)
    }else{
      this.setState({
        item:new Payment({
        })
      })
    }

    //ShopStore.addChangeListener(this.shopStoreChangeHandler)
  //
  }


  componentWillUnmount(){
    PaymentStore.removeChangeListener(this.paymentStoreChangeHandler)
    PaymentStore.removeChangeListener(this.paymentUpdatedStoreChangeHandler,"updated")
    //ShopStore.removeChangeListener(this.shopStoreChangeHandler)

  }
  paymentStoreChangeHandler(){
    this.setState({
      item:PaymentStore.getDetail()
    })
  }
  paymentUpdatedStoreChangeHandler(){
  
    this.setState({
      item:PaymentStore.getDetail(),
      display_message:true,
      message:"保存されました。"
    })
  }
  changeValueHandler(name,value){
    PaymentActions.update(name, value)
  }
  changePayJpValueHandler(name,value){
    PaymentActions.update(["setting","payjp","key"], value,true)
  }
  changePaypalValueHandler(name,value){
    PaymentActions.update(["setting","paypal",name], value,true)
  }
  changeAmazonValueHandler(name,value){
    PaymentActions.update(["setting","amazon",name], value,true)
  }
  changeBankValueHandler(name,value){
    PaymentActions.update(["setting","bank"], value,true)
  }

  clickHandler(){
    this.setState({
      display_message:false
    })
    PaymentActions.updateExe(AuthStore.getToken(), this.state.item)
  }
  clickBackHandler(){
    window.location = "/shop/settings/payment"
  }
  closeBtnHandler(){
    this.setState({
      display_message:false
    })
  }
  selectTypeChangeHandler(e){
    const target = e.target
    PaymentActions.update(target.name, Number(target.value))
  }


paypal

  render(){
    if(!this.state.item) return null
    // changeValueHandler={this.changeArePriceHandler(i)}

    //console.log(this.state.display_message)
    const addclass = this.state.display_message ? "in" : null
    const payjpkey = this.state.item.setting ? (this.state.item.setting.payjp ? this.state.item.setting.payjp.key :null) : null

    const payjp_html = this.state.item.method_type === 2 ? <Input label="PayJp Key" type="text" name="payjp.key" value={payjpkey} changeValueHandler={this.changePayJpValueHandler} /> : null

    const paypal = this.state.item.setting ? (this.state.item.setting.paypal ? this.state.item.setting.paypal :null) : null
    const amazon = this.state.item.setting ? (this.state.item.setting.amazon ? this.state.item.setting.amazon :null) : null

    var  paypal_html = null
    switch(this.state.item.method_type){
      case 1 :
        paypal_html =
        <div>
          <Input label="振込情報" type="textarea" name="bank" value={this.state.item.setting.bank} changeValueHandler={this.changeBankValueHandler} />

        </div>
      break;


      case 2 :
        paypal_html =
        <div>
          <Input label="PayPal Client ID" type="text" name="client_id" value={paypal ? paypal.client_id : null} changeValueHandler={this.changePaypalValueHandler} />
          <Input label="PayPal Secret" type="text" name="secret" value={paypal ? paypal.secret :  null} changeValueHandler={this.changePaypalValueHandler} />
          <Input label="Amazon Payment Client ID" type="text" name="client_id" value={amazon ? amazon.client_id : null} changeValueHandler={this.changeAmazonValueHandler} />
          <Input label="Amazon Payment Client Secret" type="text" name="secret" value={amazon ? amazon.secret :  null} changeValueHandler={this.changeAmazonValueHandler} />
        </div>
      break;
      default:



    }


    return(
      <div>
        <TopAlertBox text={this.state.message} addclass={addclass} closeBtnHandler={this.closeBtnHandler}/>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <SideMenuSetting id="main" />
              </section>
              <section className="main-2-pannel">

                <section className="header-info">
                  <h2>支払い方法設定</h2>
                </section>
                <Input label="名称" type="text" name="name" value={this.state.item.name} changeValueHandler={this.changeValueHandler} />
                <div className="input-box">
                  <label>支払いタイプ</label>
                  <select name="method_type" value={this.state.item.method_type} onChange={this.selectTypeChangeHandler}>
                    <option value="0">-</option>
                    <option value="1">{config.get("PAYMENT_TYPE_1")}</option>
                    <option value="2">{config.get("PAYMENT_TYPE_2")}</option>
                    <option value="3">{config.get("PAYMENT_TYPE_3")}</option>
                  </select>
                </div>
                {payjp_html}
                {paypal_html}
                <div className="btn-box">
                    <button className="white-btn midium white" onClick = {this.clickBackHandler}>戻る</button>
                  <button className="create-btn midium" onClick = {this.clickHandler}>保存</button>
                </div>

              </section>
            </div>
          </main>
      </div>
    )
  }
}
