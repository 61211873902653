import React from "react"
import {  Link } from 'react-router-dom'
import Header from "./parts/Header"
import SideMenuSetting from './parts/SideMenuSetting'
import AlertBox from './parts/AlertBox'
import PaymentActions from '../actions/PaymentActions'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'
import PaymentStore from '../stores/PaymentStore'

import config from 'react-global-configuration';

export default class PaymentApp extends React.Component {

  constructor(){
    super()
    this.paymentStoreChangeHandler = this.paymentStoreChangeHandler.bind(this)
    this.deleteBtnHandlere = this.deleteBtnHandlere.bind(this)
    this.cancelHandler =this.cancelHandler.bind(this)
    this.deleteExeHander = this.deleteExeHander.bind(this)

    AuthActions.setAuth()
    this.state = {
      list:[],
      delete_id:null,
      display_alert:false
    }
  }

  componentDidMount(){
    PaymentStore.addChangeListener(this.paymentStoreChangeHandler)
    PaymentActions.getList(AuthStore.getToken())
    //ShopStore.addChangeListener(this.shopStoreChangeHandler)
  //
  }


  componentWillUnmount(){
    PaymentStore.removeChangeListener(this.paymentStoreChangeHandler)
    //ShopStore.removeChangeListener(this.shopStoreChangeHandler)

  }
  paymentStoreChangeHandler(){
    this.setState({
      list:PaymentStore.getList()
    })
  }

  editBthHandler(id){
    window.location = "/shop/settings/payment/" + id
  }
  createNew(){
    window.location = "/shop/settings/payment/new"
  }
  cancelHandler(){
    this.setState({
      display_alert:false
    })
  }
  deleteExeHander(){
    PaymentActions.delete(AuthStore.getToken(),this.state.delete_id)
    this.setState({
      display_alert:false,
      delete_id:null
    })
  }
  deleteBtnHandlere(id){
    this.setState({
      display_alert:true,
      delete_id:id
    })
  }

  render(){

    const payment_method = this.state.list.map((item) => {
      var subtype = "-"
      if(item.setting.payjp && item.setting.payjp.key){
        subtype = "PayJp"
      }
      return <li><div className="list-title">{item.name}</div><div className="list-text-mid">{config.get("PAYMENT_TYPE_" + item.method_type)}</div><div>{subtype}</div><div className="linkbtns"><span class="link"  onClick={()=>this.editBthHandler(item.id)}><i class="fas fa-edit"></i> 編集</span>&nbsp;<span className="link" onClick={() => this.deleteBtnHandlere(item.id)}><i className="fas fa-minus-circle"></i> 削除</span></div></li>
    })
    const alert_box = this.state.display_alert ? <AlertBox ok="削除する" cancel="キャンセル"　cancelHandler={this.cancelHandler} okHandler={this.deleteExeHander}/> : null
    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <SideMenuSetting id="main" />
              </section>
              <section className="main-2-pannel">
                <section className="header-info">
                  <h2>支払い設定一覧</h2>
                  <div className="head-right">
                    <Link to="#" onClick={this.createNew}><i className="fas fa-plus-circle"></i> 新規支払い設定</Link>
                  </div>
                </section>
                <ul className="list-type1">
                  <li className="list-header"><div className="list-title">名称</div><div className="list-text-small">支払いタイプ</div><div>支払い詳細</div><div className="linkbtns"></div></li>
                  {payment_method}
                </ul>
              </section>
            </div>
          </main>
          {alert_box}
      </div>
    )
  }
}
