import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'


var NoticeActions = {

  createNew(item){
    AppDispatcher.dispatch({
      actionType: Constants.NOTICE_NEW,
      item:item
    })
  },

  update(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.NOTICE_UPDATED,
      name:name,
      value:value
    })
  },







}

export default NoticeActions
