import React from "react"

import Header from "./parts/Header"
import SideMenuSetting from './parts/SideMenuSetting'
import Input from './parts/Input'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'
import ShopActions from '../actions/ShopActions'
import ShopStore from '../stores/ShopStore'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ShopApp extends React.Component {

  constructor(){
    super()
    this.shopStoreChangeHandler = this.shopStoreChangeHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    AuthActions.setAuth()
    this.state = {
      shop:null
    }
  }

  componentDidMount(){

    ShopStore.addChangeListener(this.shopStoreChangeHandler)
    ShopActions.getDetail(AuthStore.getToken())
    //MemberActions.getMemberList(AuthStore.getToken())

  }


  componentWillUnmount(){
    ShopStore.removeChangeListener(this.shopStoreChangeHandler)
  }
  shopStoreChangeHandler(){

    this.setState({
      shop:ShopStore.getDetail()
    })
  }
  changeValueHandler(name,value){
    //console.log(name,value)
    ShopActions.update(name,value)
  }
  clickHandler(){
    ShopActions.updateExe(AuthStore.getToken(),this.state.shop)
  }

  render(){
    var main_html = null
    if(this.state.shop){
      main_html = <section className="left-pannel">
      <Input label="店舗名" type="text" name="name" value={this.state.shop.name} changeValueHandler={this.changeValueHandler} />
      <Input label="郵便番号" type="text" name="postcode" value={this.state.shop.postcode} changeValueHandler={this.changeValueHandler} />
      <Input label="住所1" type="text" name="address1" value={this.state.shop.address1} changeValueHandler={this.changeValueHandler} />
      <Input label="住所2" type="text" name="address2" value={this.state.shop.address2} changeValueHandler={this.changeValueHandler} />
      <Input label="電話" type="text" name="tel" value={this.state.shop.tel} changeValueHandler={this.changeValueHandler} />
      <Input label="メール" type="text" name="mail" value={this.state.shop.mail} changeValueHandler={this.changeValueHandler} />
      <Input label="備考" type="textarea" name="discription" value={this.state.shop.discription} changeValueHandler={this.changeValueHandler} />
      <Input label="作成日" type="text" name="created_at" value={this.state.shop.created_at} disabled={"disabled"}/>
      <Input label="更新日" type="text" name="modify_at" value={this.state.shop.modify_at} disabled={"disabled"}/>
      <Input label="Key" type="text" name="shop_key" value={this.state.shop.shop_key} disabled={"disabled"}/>
      <div className="btn-box">
        <button className="create-btn" onClick = {this.clickHandler}>保存</button>
      </div>
        </section>
    }



    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <SideMenuSetting id="main" />
              </section>
              <section className="main-2-pannel">

                  {main_html}



              </section>
            </div>
          </main>
      </div>
    )
  }
}
