import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';

var MailTemplateActions = {

  get(token,id){
    fetch(config.get("SHOP_API_PATH") + config.get("API_TEMPLATE_DETAIL").url + "/" + id, {
      method: "GET",
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return false
      }else{
        return response.status
      }
    }).then(function(data) {
      if(data !== false){
        AppDispatcher.dispatch({
          actionType: Constants.MAILTEMPLATE_DETAIL_LOADED,
          item:data,
          id:id
        })
      }else if(data === false){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  getList(token){

    fetch(config.get("SHOP_API_PATH") + config.get("API_TEMPLATE_LIST").url, {
      method: config.get("API_TEMPLATE_LIST").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){
        AppDispatcher.dispatch({
          actionType: Constants.MAILTEMPLATE_LIST_LOADED,
          list:data.templates
        })
      }else if(data === null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },

  updateExe(token,item){

    const method  = item.id ? "PATCH" : "POST"
    const url  = item.id ? config.get("SHOP_API_PATH") + config.get("API_TEMPLATE_DETAIL").url + "/" + item.id : config.get("SHOP_API_PATH") + config.get("API_TEMPLATE_DETAIL").url
    fetch(url , {
      method: method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {
      if(data != null){
        AppDispatcher.dispatch({
          actionType: Constants.MAILTEMPLATE_DETAIL_LOADED,
          item:data
        })
        AppDispatcher.dispatch({
          actionType: Constants.MAILTEMPLATE_SAVED,
        })
      }else if(data === null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  update(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.MAILTEMPLATE_UPDATED,
      name:name,
      value:value
    })
  },


}

export default MailTemplateActions
