import { Record } from 'immutable'

const MailTemplate = Record({
  id:null,
  title:null,
  body:null,
  from_address: null,
  send_type: null
});
export default MailTemplate;
