import React from "react"
import {  Link } from 'react-router-dom'
import Header from "./parts/Header"
import SideMenuSetting from './parts/SideMenuSetting'
import AlertBox from './parts/AlertBox'
import DeliveryActions from '../actions/DeliveryActions'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'
import DeliveryStore from '../stores/DeliveryStore'



import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class DeliverylApp extends React.Component {

  constructor(){
    super()
    this.deliveryStoreChangeHandler = this.deliveryStoreChangeHandler.bind(this)
    this.deleteBtnHandlere = this.deleteBtnHandlere.bind(this)
    this.cancelHandler =this.cancelHandler.bind(this)
    this.deleteExeHander = this.deleteExeHander.bind(this)

    AuthActions.setAuth()
    this.state = {
      list:[],
      delete_id:null,
      display_alert:false
    }
  }

  componentDidMount(){
    DeliveryStore.addChangeListener(this.deliveryStoreChangeHandler)
    DeliveryActions.getList(AuthStore.getToken())
    //ShopStore.addChangeListener(this.shopStoreChangeHandler)
  //
  }


  componentWillUnmount(){
    DeliveryStore.removeChangeListener(this.deliveryStoreChangeHandler)
    //ShopStore.removeChangeListener(this.shopStoreChangeHandler)

  }
  deliveryStoreChangeHandler(){
    this.setState({
      list:DeliveryStore.getList()
    })
  }

  editBthHandler(id){
    window.location = "/shop/settings/delivery/" + id
  }
  createNew(){
    window.location = "/shop/settings/delivery/0"
  }
  cancelHandler(){
    this.setState({
      display_alert:false
    })
  }
  deleteExeHander(){
    DeliveryActions.delete(AuthStore.getToken(),this.state.delete_id)
    this.setState({
      display_alert:false,
      delete_id:null
    })
  }
  deleteBtnHandlere(id){
    this.setState({
      display_alert:true,
      delete_id:id
    })
  }

  render(){

    const deliver_method = this.state.list.map((item) => {
      return <li key={item.id}><div className="list-title">{item.name}</div><div className="list-text-small">{config.get("PRODUCT_TYPE_" + item.product_type)}</div><div>{config.get("COUNTRY_" + item.country)}</div><div className="linkbtns"><span className="link" onClick={()=>this.editBthHandler(item.id)}><i className="fas fa-edit"></i> 編集</span>&nbsp;<span className="link" onClick={() => this.deleteBtnHandlere(item.id)}><i className="fas fa-minus-circle"></i> 削除</span></div></li>
    })
    const alert_box = this.state.display_alert ? <AlertBox ok="削除する" cancel="キャンセル"　cancelHandler={this.cancelHandler} okHandler={this.deleteExeHander}/> : null
    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <SideMenuSetting id="main" />
              </section>
              <section className="main-2-pannel">
                <section className="header-info">
                  <h2>配送設定一覧</h2>
                  <div className="head-right">
                    <Link to="#" onClick={this.createNew}><i className="fas fa-plus-circle"></i> 新規配送設定</Link>
                  </div>
                </section>
                <ul className="list-type1">
                  <li className="list-header"><div className="list-title">名称</div><div className="list-text-small">商品タイプ</div><div>エリア</div><div className="linkbtns"></div></li>
                  {deliver_method}
                </ul>
              </section>
            </div>
          </main>
          {alert_box}
      </div>
    )
  }
}
