import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration'



var AnalyticsActions = {

  getList(token,year){
    fetch(config.get("SHOP_API_PATH") + config.get("API_ANALYTICS") + "?year=" + year, {
      method: "GET",
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {
      console.log(data)
      if(data !== null){

        AppDispatcher.dispatch({
          actionType: Constants.ANALYTICS_LIST_LOADED,
          list:data.categories
        })
      }else if(data === null){
        window.location = "/login"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },



}

export default AnalyticsActions
