import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';



var MediaActions = {

  getList(token){
    fetch(config.get("API_PATH") + config.get("API_FILE_LIST").url, {
      method: "GET",
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){

        AppDispatcher.dispatch({
          actionType: Constants.FILE_LIST_LOADED,
          list:data.medias
        })
      }else if(data == null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },



  uploadFile(token,file){
    const formData = new FormData();
    formData.append('file', file);
    console.log(formData)
    fetch(config.get("API_PATH") + config.get("API_FILE_UPLOAD").url, {
      method: "POST",
      headers: {
       'Authorization': 'Bearer ' + token
      },
      body:formData
    }).then(function(response) {
      console.log(response)
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null ){

        AppDispatcher.dispatch({
          actionType: Constants.FILE_ADDED,
          item:data
        })
      }else if(data == null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },

  deleteFile(token,file_name){

    fetch(config.get("API_PATH") + config.get("API_FILE_DELETE").url, {
      method: config.get("API_FILE_DELETE").method,
      headers: {
        'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + token
      },
      body:JSON.stringify({file_name:file_name})
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){

        AppDispatcher.dispatch({
          actionType: Constants.FILE_DELETED,
          item:data.media
        })
        MediaActions.getList(token)
      }else if(data == null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },





}

export default MediaActions
