import { Record } from 'immutable'

const Trade = Record({
  id:undefined,
  payments:[],
  products:[],
  total_price:null,
  product_total_price:null,
  tax:null,
  delivery_price:null,
  delivery_method:null,
  payment_method:null,
  shipping_info:null,
  customer_info:null,
  comment:null,
  status:null,
  created_at:null
});
export default Trade;
