import { Record } from 'immutable'
import PayJp from './PayJp'
import Paypal from './Paypal'
import Amazon from './Amazon'

const PaySetting = Record({
  payjp:new PayJp(),
  paypal:new Paypal(),
  amazon:new Amazon(),
  bank:null
});
export default PaySetting;
