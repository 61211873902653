import React from "react"



/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ProductMediaItem extends React.Component {

  constructor(){
    super()
    this.clickHandler = this.clickHandler.bind(this)
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
    this.state = {
      delete_display:false
    }
  }

  componentDidMount(){


  }

  componentWillUnmount(){

  }
  clickHandler(){
    this.props.disatachImage(this.props.item.id,this.props.item.media_key)
    //this.props.onClickHandler(this.props.item)
  }
  /* Handlers */

  memberStoreChangeHandler(){
  }

  onMouseOver(){
    this.setState({
      delete_display:true
    })
  }
  onMouseOut(){

    this.setState({
      delete_display:false
    })
  }


  render(){

    const delete_btn = this.state.delete_display ? <div className="over-flow-back-item"><div className="product_image_delete link" onClick={this.clickHandler}><i class="fas fa-minus-circle"></i> 削除</div></div> : null

    return(
      <div className="product_image" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseOut}>
        <div className="product_image_image">
          <img src={this.props.item.url} alt="product"/>
          {delete_btn}
        </div>

        <div className="product_image_key">{this.props.item.media_key}</div>
      </div>
    )
  }
}
