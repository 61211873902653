import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"


var CHANGE_EVENT = "change" // chenge evnetを定数にする

var _item = null


var setDetail = (item) => {
  _item = item
}

var ApiStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.API_GET_ITEM:
      setDetail(action.item);
      ApiStore.emitChange()
      break


    default:


      // no
  }
})
export default ApiStore
