import React from "react"





/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ListMediaItem extends React.Component {

  constructor(){
    super()
    this.onClickHandler = this.onClickHandler.bind(this)
    this.state = {
      closeBtn:false
    }
  }

  componentDidMount(){


  }

  componentWillUnmount(){

  }
  onClickHandler(){
    this.props.onClickHandler(this.props.item)
  }
  /* Handlers */

  memberStoreChangeHandler(){
  }




  render(){


    return(
      <li onClick={this.onClickHandler}>
        <img src={this.props.item.url} alt="product" />
      </li>
    )
  }
}
