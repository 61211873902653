import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';



var SettingActions = {


  getList(token, key_type = ""){

    fetch(config.get("SHOP_API_PATH") + config.get("API_SETTING_LIST").url + "?key_type=" + key_type, {
      method: config.get("API_SETTING_LIST").method,
      headers: {
       'Authorization': 'Bearer ' + token
     },
    }).then(function(response) {
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){
        AppDispatcher.dispatch({
          actionType: Constants.SETTING_LIST_LOADED,
          list:data.settings
        })
      }else if(data === null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },


  updateExe(token,item, name=""){
    const id_str = item.id ? "/" + item.id : ""
    const method = item.id ? "PATCH" : "POST"

    fetch(config.get("SHOP_API_PATH") + config.get("API_SETTING_UPDATE").url + id_str, {
      method: method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify(item)
    }).then(function(response) {
      console.log(response)
      if(response.status === 200){
        return response.json()
      }else if(response.status === 401){
        return null
      }else{
        return response.status
      }
    }).then(function(data) {

      if(data !== null){
        SettingActions.getList(token, name)

      }else if(data === null){
        window.location = "/"
      }else{
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:data
        })
      }
    })
  },

  delete(token,id){

    fetch(config.get("SHOP_API_PATH") + config.get("API_SETTING_DELETE").url + id, {
      method: config.get("API_SETTING_DELETE").method,
      headers: {
       'Authorization': 'Bearer ' + token,
       'Content-Type': 'application/json'
      },
      body: JSON.stringify({id:id})
    }).then(function(response) {
      if(response.status === 204){
        SettingActions.getList(token)
      }else if(response.status === 401){
        AppDispatcher.dispatch({
          actionType: Constants.API_ERROR,
          code:401
        })
      }else{
          console.log(response.status + "/" +response.body)
      }
    })
  },

  update(name,value){
    AppDispatcher.dispatch({
      actionType: Constants.SETTING_DETAIL_UPDATED,
      name:name,
      value:value
    })
  },



}

export default SettingActions
