import React from "react"
import {  Link } from 'react-router-dom'

import MediaActions from '../../actions/MediaActions';
import ProductActions from '../../actions/ProductActions';
import AuthStore from '../../stores/AuthStore'
import MediaStore from '../../stores/MediaStore'

import ListMediaItem from './ListMediaItem'
import MediaDetail from './MediaDetail'
import FileUploader from './FileUploader'


export default class SelectMedia extends React.Component {

  constructor(){
    super()
    this.mediaStoreChangeHandler = this.mediaStoreChangeHandler.bind(this)
    this.displayUploadFile = this.displayUploadFile.bind(this)
    this.closeBtnHandler =this.closeBtnHandler.bind(this)
    this.closeHandler = this.closeHandler.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    this.mediaStoreAddChangeHandler = this.mediaStoreAddChangeHandler.bind(this)
    this.onClickHandler = this.onClickHandler.bind(this)
    this.deleteFile = this.deleteFile.bind(this)
    this.selectedItem =this.selectedItem.bind(this)
    this.state = {
      list:[],
      upload:false
    }
  }

  componentDidMount(){
    MediaStore.addChangeListener(this.mediaStoreChangeHandler)
    MediaStore.addChangeListener(this.mediaStoreAddChangeHandler,"add_change")

    MediaActions.getList(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberListChangeHandler)
    //MemberActions.getMemberList(AuthStore.getToken())

  }


  componentWillUnmount(){
    MediaStore.removeChangeListener(this.mediaStoreChangeHandler)
    MediaStore.removeChangeListener(this.mediaStoreAddChangeHandler,"add_change")
  }

  mediaStoreChangeHandler(){
    this.setState({
      list:MediaStore.getList().reverse()
    })
  }
  mediaStoreAddChangeHandler(){
    this.setState({
      list:MediaStore.getList(),
      upload:false,
      detail:false,
      detail_item:null
    })
  }

  deleteFile(file_name){
    this.setState({
      detail:false,
      detail_item:null
    })
    MediaActions.deleteFile(AuthStore.getToken(), file_name)
  }

  displayUploadFile(e){
    e.preventDefault()
    this.setState({
      upload:true
    })
  }
  closeBtnHandler(){
    this.setState({
      upload:false,
      detail:false
    })
  }
  closeHandler(){
    this.props.closeHandler()
  }
  uploadFile(file){
    MediaActions.uploadFile(AuthStore.getToken(),file )
  }
  onClickHandler(item){
    this.setState({
      detail:true,
      detail_item:item
    })
  }
  selectedItem(){
    ProductActions.attachOnProduct(AuthStore.getToken(),this.state.detail_item.id,this.props.selected_ptoduct_id,this.props.media_type)
    this.props.selectedHandler()
    this.setState({
      upload:false,
      detail:false
    })
  }

  render(){

    const listHtml = this.state.list.map((item) => {
      if(item.media_type === "image/jpeg" || item.media_type === "image/png")
      return <ListMediaItem item={item} onClickHandler={this.onClickHandler}/>
      else return null
    })


    return(
      <div class="over-flow-back">

      <div className="closebtn" onClick={this.closeHandler}><i class="fas fa-times-circle"></i></div>
      <MediaDetail type={"select"} is_display = {this.state.detail} closeBtnHandler={this.closeBtnHandler} item={this.state.detail_item} deleteFile={this.deleteFile} selectedItem={this.selectedItem}/>
      <FileUploader is_display={this.state.upload} closeBtnHandler={this.closeBtnHandler} uploadFile={this.uploadFile}/>
      <div className="main-pannel-1">
        <section className="header-info">
          <div className="head-right">
              <Link to="#" onClick={this.displayUploadFile}><i class="fas fa-plus-circle"></i> 新規ファイル</Link>
          </div>
        </section>

        <ul className="media-list over-flow-content">
            {listHtml}
        </ul>

      </div>

    </div>
    )
  }
}
