import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import LoginApp from './components/LoginApp'
import DashBoardApp from './components/DashBoardApp'
import ProductApp from './components/ProductApp'
import ProductDetailApp from './components/ProductDetailApp'
import MediaApp from './components/MediaApp'
import CategoryApp from './components/CategoryApp'
import CategoryDetailApp from './components/CategoryDetailApp'
import ShopApp from './components/ShopApp'
import NoticeApp from './components/NoticeApp'
import MailTemplateApp from './components/MailTemplateApp'
import MailTemplateDetailApp from './components/MailTemplateDetailApp'
import DeliveryApp from './components/DeliveryApp'
import DeliveryDetailApp from './components/DeliveryDetailApp'
import PaymentApp from './components/PaymentApp'
import PaymentDetailApp from './components/PaymentDetailApp'
import TradeApp from './components/TradeApp'
import TradeDetailApp from './components/TradeDetailApp'
import TaxApp from './components/TaxApp'
import AnalyticsApp from './components/AnalyticsApp'

import ApiApp from './components/ApiApp'
import ApiTopApp from './components/ApiTopApp'
config.set(configuration);

class App extends React.Component{


render(){
return(
     <div>
        <Route exact path='/' component={LoginApp} />
        <Route exact path='/shop' component={DashBoardApp} />
        <Route exact path='/shop/product' component={ProductApp} />
        <Route exact path='/shop/product/:id' component={ProductDetailApp} />
        <Route exact path='/shop/media' component={MediaApp} />
        <Route exact path='/shop/category' component={CategoryApp} />
        <Route exact path='/shop/category/:id' component={CategoryDetailApp} />
        <Route exact path='/shop/settings' component={ShopApp} />
        <Route exact path='/shop/settings/tax' component={TaxApp} />
        <Route exact path='/shop/settings/notice' component={NoticeApp} />
        <Route exact path='/shop/settings/template/mail' component={MailTemplateApp} />
        <Route exact path='/shop/settings/template/mail/:id' component={MailTemplateDetailApp} />
        <Route exact path='/shop/settings/delivery' component={DeliveryApp} />
        <Route exact path='/shop/settings/delivery/:id' component={DeliveryDetailApp} />
        <Route exact path='/shop/settings/payment' component={PaymentApp} />
        <Route exact path='/shop/settings/payment/:id' component={PaymentDetailApp} />
        <Route exact path='/shop/trade' component={TradeApp} />
        <Route exact path='/shop/trade/search' component={TradeApp} />
        <Route exact path='/shop/trade/search/:status' component={TradeApp} />
        <Route exact path='/shop/trade/:id' component={TradeDetailApp} />
        <Route exact path='/shop/analytics' component={AnalyticsApp} />
        <Route exact path='/api' component={ApiTopApp} />
        <Route exact path='/api/:category/:api' component={ApiApp} />


      </div>
)}}

ReactDOM.render(
  (<BrowserRouter><App /></BrowserRouter>),
  document.getElementById('root')
)
