import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Trade from "../vo/Trade"

var CHANGE_EVENT = "change" // chenge evnetを定数にする


var _items = []
var _item = null
var _total = null

var setList = (items) =>{
  _items = items.map((item) => {
    return TradeStore.createRecord(item)
  })
}

var setDetail = (item,id) =>{

  _item = item ? TradeStore.createRecord(item) : new TradeStore()
}

var update = (name,value) => {
  _item = _item.set(name,value)
}

var setTotal = (total) => {
  _total = total
}


var TradeStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },
  getList(){
    return _items
  },

  getTotal(){
    return _total
  },

  createRecord(item){

    return new Trade(
    {
      id:item.id,
      payments:item.payments,
      products:item.products,
      total_price:item.total_price,
      product_total_price:item.product_total_price,
      tax:item.tax,
      delivery_price:item.delivery_price,
      delivery_method:item.delivery_method,
      payment_method:item.payment_method,
      shipping_info:item.shipping_info,
      customer_info:item.customer_info,
      comment:item.comment,
      status:item.status,
      created_at:item.created_at
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.TRADE_DETAIL_LOADED:
      setDetail(action.item,action.id)
      TradeStore.emitChange()
    break
    case Constants.TRADE_LIST_LOADED :
      setList(action.list)
      setTotal(action.total)
      TradeStore.emitChange()
    break;

    case Constants.TRADE_UPDATE:
      update(action.name,action.value);
      TradeStore.emitChange()
      break;

    default:

  }
})
export default TradeStore
