import React, { useState,useEffect,useCallBack } from 'react';
import Header from "./parts/Header"
import AuthActions from '../actions/AuthActions'
import AnalyticsActions from '../actions/AnalyticsActions';

import AuthStore from '../stores/AuthStore'


/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/


function AnalyticsApp(props) {


  const [list, setList] = useState([]);
  const stableListChangHandler = useCallBack(listChangeHandler, [])

  useEffect(() => {
      AuthActions.setAuth()

      AnalyticsActions.getList(AuthStore.getToken(),2019)
      return () => {

      };
    },
    [stableListChangHandler],
  );


  function listChangeHandler() {
    console.log("list change")
    setList([])
  }



  return(
    <div>
      <Header />
      <main>
        <div className="main-pannel-1">
          <section className="header-info">
          </section>
          <ul className="product-item">
            {list}
          </ul>
        </div>
      </main>
    </div>
  )

}

export default AnalyticsApp
