import React from "react"

import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class SelectMedia extends React.Component {

  constructor(){
    super()

    this.state = {

    }
  }

  componentDidMount(){

    //MemberStore.addChangeListener(this.memberListChangeHandler)
    //MemberActions.getMemberList(AuthStore.getToken())

  }


  componentWillUnmount(){

  }

  onClickHandler(id){
    this.props.selectOpt(id)
  }


  render(){

    const listHtml = this.props.list.map((item) => {
      const category_name = item.category ? item.category.name : "-"
      return  <li onClick={() => this.onClickHandler(item.id)}>
          <div className="product-list-title">{item.title}</div>
          <div className="product-list-sub_title">{item.sub_title}</div>
          <div className="product-list-category">{category_name}</div>
          <div className="product-list-price">{item.price.toLocaleString()}円</div>
          <div className="product-list-status">{config.get("status_" + item.status)}</div>
        </li>
    })


    return(
      <div class="over-flow-back">
        <div className="closebtn" onClick={this.props.closeHandler}><i class="fas fa-times-circle"></i></div>

        <div className="main-pannel-1">
          <ul className="product-item ">
              {listHtml}
          </ul>
        </div>
      </div>
    )
  }
}
