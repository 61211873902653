import React from "react"
import {  Link } from 'react-router-dom'
import Header from "./parts/Header"
import AuthActions from '../actions/AuthActions'
import ProductActions from '../actions/ProductActions';
import AuthStore from '../stores/AuthStore'
import ProductStore from '../stores/ProductStore'
import ListProductItem from './parts/ListProductItem'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ProductApp extends React.Component {

  constructor(){
    super()
    this.prodcutStoreChangeHandler = this.prodcutStoreChangeHandler.bind(this)
    AuthActions.setAuth()
    this.state = {
      list:[]
    }
  }

  componentDidMount(){
    ProductStore.addChangeListener(this.prodcutStoreChangeHandler)
    ProductActions.getList(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberListChangeHandler)
    //MemberActions.getMemberList(AuthStore.getToken())

  }


  componentWillUnmount(){
    ProductStore.removeChangeListener(this.prodcutStoreChangeHandler)
  }

  prodcutStoreChangeHandler(){
    this.setState({
      list:ProductStore.getList()
    })

  }
  render(){
    const listHtml = this.state.list.map((item) => {
      return <ListProductItem item={item} key={"product_" + item.id}/>
    })


    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-1">
              <section className="header-info">
                <div className="head-right">
                  <Link to="/shop/product/new"><i className="fas fa-plus-circle"></i> 新規商品</Link>
                </div>
              </section>

                <ul className="product-item">
                  {listHtml}
                </ul>

            </div>
          </main>
      </div>
    )
  }
}
