import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"

import Constants from "../constants/Constants"
import assign from "object-assign"
import Category from "../vo/Category"


var CHANGE_EVENT = "change" // chenge evnetを定数にする
var UPDATE_EVENT = "update"

var _item = new Category()
var _list = []
var _flat_list = []




var setDetail = (item) =>{
  _item = CategoryStore.createRecord(item,[])
}

var setList = (items) => {
  var __list = []

  const ___flat_list = items.map((item) =>{
    //findChild

    if(item.parent_id === null || item.parent_id === 0){
      __list.push(CategoryStore.createRecord(item,items))
    }

    return CategoryStore.createRecord(item,[])

  })
  _list = __list
  _flat_list = ___flat_list
}

var update = (name,value) => {
  _item = _item.set(name,value)
}


var CategoryStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },
  getList(){
    return _list
  },

  getDetailFormList(id){

    for(var i in _flat_list){
      if(_flat_list[i].id === id){
        return _flat_list[i]
      }
    }
    return null
  },

  createRecord(item,items){
    var children = []
    for(var i in items){
      if(items[i].parent_id === item.id){
        children.push(CategoryStore.createRecord(items[i],items))
      }
    }


    return new Category(
    {
      id:item.id,
      name:item.name,
      parent_id:item.parent_id,
      used_number:item.used_number,
      children:children
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.CATEGORY_LIST_LOADED:
      setList(action.list);
      CategoryStore.emitChange()
      break;

    case Constants.CATEGORY_DETAIL_UPDATED:
      update(action.name,action.value);
      CategoryStore.emitChange(UPDATE_EVENT)
      break
    case Constants.CATEGORY_DETAIL_LOADED:
      setDetail(action.item);
      CategoryStore.emitChange()
      break


    default:


      // no
  }
})
export default CategoryStore
