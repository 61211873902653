import React from "react"
import moment from 'moment'

import Header from "./parts/Header"
import AuthActions from '../actions/AuthActions'
import ProductActions from '../actions/ProductActions';
import CategoryActions from '../actions/CategoryActions';
import AuthStore from '../stores/AuthStore'
import ProductStore from '../stores/ProductStore'
import CategoryStore from '../stores/CategoryStore'
import Product from '../vo/Product'
import config from 'react-global-configuration';
import Input from './parts/Input'
import SelectMedia from './parts/SelectMedia'
import ProductMediaItem from './parts/ProductMediaItem'
import TopAlertBox  from './parts/TopAlertBox'
import DeliveryActions from '../actions/DeliveryActions'
import DeliveryStore from '../stores/DeliveryStore'
import PaymentActions from '../actions/PaymentActions'
import PaymentStore from '../stores/PaymentStore'
import SelectProduct from './parts/SelectProduct'

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ProductDetailApp extends React.Component {

  constructor({match}){
    super()
    this.prodcutStoreChangeHandler = this.prodcutStoreChangeHandler.bind(this)
    this.prodcutUpdatedStoreChangeHandler = this.prodcutUpdatedStoreChangeHandler.bind(this)
    this.deliveryStoreChangeHandler = this.deliveryStoreChangeHandler.bind(this)
    this.paymentStoreChangeHandler = this.paymentStoreChangeHandler.bind(this)

    this.clickHandler = this.clickHandler.bind(this)
    this.updateMainImage = this.updateMainImage.bind(this)
    this.closeHandler = this.closeHandler.bind(this)
    this.closeBtnHandler = this.closeBtnHandler.bind(this)
    this.changeValueHandler = this.changeValueHandler.bind(this)
    this.disattach = this.disattach.bind(this)
    this.selectedHandler = this.selectedHandler.bind(this)
    this.addImage = this.addImage.bind(this)
    this.disatachImage = this.disatachImage.bind(this)
    this.categoryStoreChangeHandler = this.categoryStoreChangeHandler.bind(this)
    this.selectChangeHandler = this.selectChangeHandler.bind(this)
    this.optionProductChangeHandler = this.optionProductChangeHandler.bind(this)
    this.addOpt = this.addOpt.bind(this)
    this.selectedOptHandler = this.selectedOptHandler.bind(this)
    this.toggleStock = this.toggleStock.bind(this)
    AuthActions.setAuth()
    this.id = match.params.id
    this.state = {
      item:Product(),
      category:[],
      delivery:[],
      payment:[],
      products:[],
      status:0,
      media_list_display:false,
      upload_mediatype:null,
      image_key:null,
      display_message:false,
      message:null,
      display_op_product:false,
      display_detail_stock:false,
      delete_flg:false

    }
  }

  componentDidMount(){

    ProductStore.addChangeListener(this.prodcutStoreChangeHandler)
    ProductStore.addChangeListener(this.prodcutUpdatedStoreChangeHandler,"product_updated")
    ProductStore.addChangeListener(this.prodcutStoreDeletedHandler,"deleted")
    CategoryStore.addChangeListener(this.categoryStoreChangeHandler)
    DeliveryStore.addChangeListener(this.deliveryStoreChangeHandler)
    PaymentStore.addChangeListener(this.paymentStoreChangeHandler)
    DeliveryActions.getList(AuthStore.getToken())
    PaymentActions.getList(AuthStore.getToken())
    ProductActions.getList(AuthStore.getToken())

    if(this.id && this.id !== "new"){
        ProductActions.getDetail(AuthStore.getToken(),this.id)
    }else{
      ProductActions.setNew({options:[]})
    }
    CategoryActions.getList(AuthStore.getToken())

    //MemberStore.addChangeListener(this.memberListChangeHandler)
    //MemberActions.getMemberList(AuthStore.getToken())

  }


  componentWillUnmount(){
    DeliveryStore.removeChangeListener(this.deliveryStoreChangeHandler)
    PaymentStore.removeChangeListener(this.paymentStoreChangeHandler)
    ProductStore.removeChangeListener(this.prodcutStoreChangeHandler)
    CategoryStore.removeChangeListener(this.categoryStoreChangeHandler)
    ProductStore.removeChangeListener(this.prodcutUpdatedStoreChangeHandler,"product_updated")
    ProductStore.removeChangeListener(this.prodcutStoreDeletedHandler,"deleted")
  }

  prodcutStoreChangeHandler(){
    this.setState({
      item:ProductStore.getDetail(),
      products:ProductStore.getList(),
      status:ProductStore.getStatus()
    })
  }
  prodcutStoreDeletedHandler(){
    console.log("deleted")
  }
  clickHandler(){
    ProductActions.updateExe(AuthStore.getToken(), this.state.item)
  }
  selectChangeHandler(e){
    const target = e.target
    this.changeValueHandler("category", Number(target.value))
  }
  changeValueHandler(name,value){
    ProductActions.update(name,value)
    this.setState({
      display_message:false
    })
  }
  categoryStoreChangeHandler(){
    this.setState({
      category:CategoryStore.getList()
    })
  }
  deliveryStoreChangeHandler(){
    this.setState({
      delivery:DeliveryStore.getList()
    })
  }
  paymentStoreChangeHandler(){
    this.setState({
      payment:PaymentStore.getList()
    })
  }
  getMainImage(){
    var result = null
    for(var i in this.state.item.medias ){
      if(this.state.item.medias[i].media_key === "main_image"){
        result =  this.state.item.medias[i]
      }
    }
    return result
  }
  disattach(){
    const media = this.getMainImage()
    if(media){
      ProductActions.disAttachOnProduct(AuthStore.getToken(),media.id,this.state.item.id,media.media_key)
    }
  }

  disatachImage(media_id,media_key){
    ProductActions.disAttachOnProduct(AuthStore.getToken(),media_id,this.state.item.id,media_key)
  }

  updateMainImage(){
    this.setState({
      media_list_display:true,
      upload_mediatype:"main_image"
    })
  }


  selectedHandler(){
    this.setState({
      media_list_display:false
    })
  }
  optionProductChangeHandler(e){
    const target = e.target
    this.setState({
      newoptionid:target.value
    })
  }

  closeHandler(){
    this.setState({
      media_list_display:false,
      display_op_product:false
    })
  }

  toggleStock(id){
    this.setState({
      display_detail_stock:id
    })
  }


  clickBackHandler(){
    window.location = "/shop/product"
  }
  prodcutUpdatedStoreChangeHandler(){
    this.setState({
      item:ProductStore.getDetail(),
      status:ProductStore.getStatus(),
      display_message:true,
      message:"保存されました。"
    })
  }
  closeBtnHandler(){
    this.setState({
      display_message:false
    })
  }
  selectedOptHandler(id){
    ProductActions.update("options",id)
    this.setState({
      display_op_product:false
    })
  }
  deleteItem(id){
    ProductActions.update("options_delete",id)
  }


  addImage(){
    const day = new Date()
    this.setState({
      media_list_display:true,
      upload_mediatype:"image_" + day.getTime()
    })
  }
  addOpt(){
    this.setState({
      display_op_product:true
    })
  }
  addX(){
    ProductActions.update("x_name_add",0)
  }
  updteX(name,value){
    ProductActions.updateList("x_name_value",value,Number(name))
  }
  addY(){
    ProductActions.update("y_name_add",0)
  }
  updteY(name,value){

    ProductActions.updateList("y_name_value",value,Number(name))
  }
  updteMatrix(name,value){
    ProductActions.updateList("matrix_value",value,name)
  }
  getInputItem(item,step){
    var spacer = ""
    for(var i = 0; i < step; i++){
      spacer = spacer + "　"
    }
    spacer = spacer + "|-"
    var items = []

    items.push(<option value={item.id} key={"opt_" + item.id}>{spacer + item.name}</option> )

    for(let k in item.children){
      const _item = item.children[k]
      if(_item){
        items.push(this.getInputItem(_item, step+1))
      }
    }
    return items
  }
  getMatrixRecord(x,y){
    //console.log(this.state.item.stock)
    for(var i in this.state.item.stock){
      const target = this.state.item.stock[i]
      if(Number(target.x) === Number(x) && Number(target.y) === Number(y)) return target.value
    }
    return null
  }



  render(){
    //console.log(this.state.category)
    const category = this.state.category.map((item) => {
      return this.getInputItem(item,0)
    })


    //const option_product

    if(this.state.item == null) return null


    const selectMediaHtml = this.state.media_list_display ? <SelectMedia closeHandler={this.closeHandler} selected_ptoduct_id={this.state.item.id} media_type={this.state.upload_mediatype} selectedHandler={this.selectedHandler}/> : null
    const created_time = this.state.item.updated_at ? moment(this.state.item.updated_at).format("YYYY-MM-DD HH:mm") : "-"
    const image_item = this.getMainImage()
    const add_main_image = this.state.item.id && this.state.item.id > 0 ? <div className="link" onClick={this.updateMainImage}><i className="far fa-image"></i> メイン画像を設定</div> : null
    const mainimage = image_item ? <div><img src={image_item.url} alt="product"/> <div className="right link" onClick={this.disattach}><i className="fas fa-minus-circle"></i> 設定を解除</div></div>: add_main_image

    const images_html = this.state.item.medias.map((item) => {
      if(item.media_key !== "main_image")
      return <ProductMediaItem item={item} disatachImage={this.disatachImage} key={"media_" + item.id}/>
      else return null
    })
    const add_image_btn = this.state.item.id && this.state.item.id > 0 ? <div className="product-image-add link" onClick={this.addImage}><i className="fas fa-plus-circle"></i>&nbsp;画像追加</div> : null

    const category_id = this.state.item.category ? this.state.item.category : undefined

    const derivery = this.state.delivery.map((item) =>{
      return {value:item.id,title:item.name}
    })

    const payment = this.state.payment.map((item) => {
      return {value:item.id,title:item.name}
    })

    const selectOptHtml = this.state.display_op_product ? <SelectProduct list = {this.state.products} closeHandler={this.closeHandler}  selectOpt={this.selectedOptHandler}/> : null

    const addclass = this.state.display_message ? "in" : null

    const option_products_html = this.state.item.options ? this.state.item.options.map((item) => {
      return <li key={"option_" + item.id}>{item.title} | <span className="link" onClick={() => this.deleteItem(item.id)}>解除</span></li>
    }) : null

    var  matrix_html = []
    var matrix_header  = []
    matrix_header.push(<th key="firstth"></th>)
    var i = 0
    for(i in this.state.item.x_name.value){
      matrix_header.push(<th key={"th_"+i}>{this.state.item.x_name.value[i]}</th>)
    }
    matrix_html.push(<tr key="firsttr">{matrix_header}</tr>)


    for(i in this.state.item.y_name.value){
      var matrix_tr = []
      matrix_tr.push(<th key={"t_th_"+i}>{this.state.item.y_name.value[i]}</th>)
      for(var k in this.state.item.x_name.value){
        matrix_tr.push(<td key={"t_td_"+i+"_"+k}><Input label={this.state.item.y_name.value[i] +"/"+this.state.item.x_name.value[k] }  value={this.getMatrixRecord(k,i)} type="number" divclass="small-1" name={k + "_" +i}   changeValueHandler={this.updteMatrix}/></td>)
      }
      matrix_html.push(<tr key={"t_tr_"+i}>{matrix_tr}</tr>)
    }

    var  x_name_vals = []
    for(i in this.state.item.x_name.value){
      x_name_vals.push(<Input key={"x_" + i} label="種別" placeholder="赤" type="text" divclass="small-1" name={i}  value={this.state.item.x_name.value[i]} changeValueHandler={this.updteX}/>)
    }

    var  y_name_vals = []
    for(i in this.state.item.y_name.value){
      y_name_vals.push(<Input key={"y_" + i} label="種別" placeholder="M" type="text" divclass="small-1" name={i}  value={this.state.item.y_name.value[i]} changeValueHandler={this.updteY}/>)
    }

    const stock_html = (!this.state.display_detail_stock && this.state.item.y_name.name === null && this.state.item.x_name.name === null) ? (
      <div className="input-box">
        <label>在庫（サイズ・種別等の指定なし）</label>
        <div className="price-area noboarder">
          <Input label="在庫設定" value={this.getMatrixRecord(0,0)} type="number" divclass="small-1" name={"0_0"}   changeValueHandler={this.updteMatrix}/>
          <div className="link" onClick={() => this.toggleStock(true)}><i className="far fa-arrow-alt-circle-down"></i>&nbsp;サイズ・種別等の詳細在庫設定</div>
        </div>
      </div>
    ):(
      <div>
      <div className="input-box">
        <label>横軸設定</label>
        <div className="price-area">
          <Input label="名称" placeholder="カラー" type="text" divclass="small-1" name="x_name"  value={this.state.item.x_name.name} changeValueHandler={this.changeValueHandler}/>
        </div>
        <div className="price-area noboarder">
          {x_name_vals}
          <div className="link" onClick={this.addX}><i className="fas fa-plus-circle"></i>&nbsp;追加</div>
        </div>
      </div>

      <div className="input-box">
        <label>縦軸設定</label>
        <div className="price-area">
          <Input label="名称" placeholder="サイズ" type="text" divclass="small-1" name="y_name"  value={this.state.item.y_name.name} changeValueHandler={this.changeValueHandler}/>
        </div>
        <div className="price-area noboarder">
          {y_name_vals}
          <div className="link" onClick={this.addY}><i className="fas fa-plus-circle"></i>&nbsp;追加</div>
        </div>
      </div>


      <div className="input-box">
        <label>在庫設定</label>
        <table className="stock-table">
          <tbody>
          {matrix_html}
          </tbody>
        </table>
      </div>
      </div>
    )

    return(
      <div>
        {selectMediaHtml}
        {selectOptHtml}
        <TopAlertBox text={this.state.message} addclass={addclass} closeBtnHandler={this.closeBtnHandler}/>
        <Header />
          <main>
            <div className="main-pannel-2">
              <section className="left-pannel">
                <Input label="商品名" type="text" name="title" value={this.state.item.title} changeValueHandler={this.changeValueHandler} />
                <Input label="商品サブタイトル" type="text" name="sub_title" value={this.state.item.sub_title} changeValueHandler={this.changeValueHandler} />
                <div className="input-box">
                  <label>カテゴリー</label>
                  <select name="parent_id" value={category_id} onChange={this.selectChangeHandler}>
                    <option value="0">-</option>
                    {category}
                  </select>
                </div>
                <Input label="金額" type="number" name="price" value={this.state.item.price} changeValueHandler={this.changeValueHandler} />
                <Input label="単位" type="text" name="unit" value={this.state.item.unit} changeValueHandler={this.changeValueHandler} />
                <Input label="配送方法"  type="checkbox" name="delivery_method" value={this.state.item.delivery_method} items={derivery} changeValueHandler={this.changeValueHandler} />
                <Input label="支払い方法"  type="checkbox" name="payment_method" value={this.state.item.payment_method} items={payment} changeValueHandler={this.changeValueHandler} />
                <Input label="詳細説明" type="textarea" name="description" value={this.state.item.description} changeValueHandler={this.changeValueHandler} />
                <Input label="ステータス" type="select" name="status" value={this.state.item.status} changeValueHandler={this.changeValueHandler} select_values="0,1"/>
                {stock_html}
                <label className="status-label">商品画像</label>
                <div className="product-image-box">
                  {images_html}
                  {add_image_btn}
                </div>
                <div className="btn-box">
                  <button className="white-btn midium" onClick = {this.clickBackHandler}>戻る</button>
                  <button className="create-btn midium" onClick = {this.clickHandler}>保存</button>
                </div>

              </section>
              <section className="right-pannel">
                <div className={"status-box status_" + this.state.status}>{config.get("status_" + this.state.status)}</div>
                <div className="status-box">
                  <div className="status-label">最終更新日</div>
                  <div className="status-content">{created_time}</div>
                  <div className="status-label">オプション商品</div>
                  <div className="status-content">
                    <ul >{option_products_html}</ul>
                    <div className="link" onClick={this.addOpt}><i className="fas fa-plus-circle"></i>&nbsp;商品追加</div>


                  </div>
                  <div className="status-label">メイン画像</div>
                  <div className="status-content">{mainimage}</div>
                </div>


              </section>

            </div>
          </main>
      </div>
    )
  }
}
