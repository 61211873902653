import React from "react"

import Header from "./parts/Header"
import SideMenuSetting from './parts/SideMenuSetting'
import Input from './parts/Input'
import MessageBox from './parts/MessageBox'
import AuthActions from '../actions/AuthActions'
import MailTemplateActions from '../actions/MailTemplateActions'
import AuthStore from '../stores/AuthStore'
import MailTemplateStore from '../stores/MailTemplateStore'


import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class MailTemplateDetailApp extends React.Component {

  constructor({match}){
    super()
    this.mailTemplateStoreChangeHandler = this.mailTemplateStoreChangeHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.updatedHandler = this.updatedHandler.bind(this)
    this.closeMessageBox = this.closeMessageBox.bind(this)
    //this.editBthHandler = this.editBthHandler.bind(this)
    this.id = match.params.id
    AuthActions.setAuth()
    this.state = {
      item:null,
      message_box:null
    }
  }

  componentDidMount(){
    MailTemplateStore.addChangeListener(this.mailTemplateStoreChangeHandler)
    MailTemplateStore.addChangeListener(this.updatedHandler, "updated")
    MailTemplateActions.get(AuthStore.getToken(), this.id)
  }


  componentWillUnmount(){
    MailTemplateStore.removeChangeListener(this.mailTemplateStoreChangeHandler)
    MailTemplateStore.removeChangeListener(this.updatedHandler, "updated")
    //ShopStore.removeChangeListener(this.shopStoreChangeHandler)

  }
  mailTemplateStoreChangeHandler(){
    this.setState({
      item:MailTemplateStore.getDetail()
    })
  }

  updatedHandler(){
    console.log(this)
    this.setState({
      message_box:<MessageBox className="active" message="更新しました" close={this.closeMessageBox}/>
    })
  }

  changeValueHandler(name,value){
    MailTemplateActions.update(name,value)
  }

  clickHandler(){
    MailTemplateActions.updateExe(AuthStore.getToken(),this.state.item)
  }
  closeMessageBox(){
    this.setState({
      message_box:null
    })
  }
  render(){

    if(!this.state.item) return null

    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <SideMenuSetting id="main" />
              </section>
              <section className="main-2-pannel">
                <section className="header-info">
                  <h2>テンプレート : {config.get("NOTICE_order_created")}</h2>
                </section>
                <Input label="メールタイトル" type="text" name="title" value={this.state.item.title} changeValueHandler={this.changeValueHandler} />
                <Input label="From" type="mail" name="from_address" value={this.state.item.from_address} changeValueHandler={this.changeValueHandler} />
                <Input label="本文" type="textarea" name="body" value={this.state.item.body} changeValueHandler={this.changeValueHandler} />
                <div className="btn-box">
                  <button className="create-btn" onClick = {this.clickHandler}>保存</button>
                </div>
              </section>

            </div>
          </main>
          {this.state.message_box}
      </div>
    )
  }
}
