import React from "react"
import moment from 'moment'


const Common = {
  getDateString: function(date) {
    if(!date) return "-"
    else return moment(date).format('YYYY-MM-DD')
  },
  getDateJpString: function(date) {
    if(!date) return "-"
    else return moment(date).format('YYYY年MM月DD日')
  },
  getDateTimeJpString: function(date) {
    if(!date) return "-"
    else return moment(date).format('YYYY年MM月DD日 HH:mm')
  },
  nl2br: function(text) {
    let regex = /(\n)/g
    return text.split(regex).map((line, i) => {
        if (line.match(regex)) {
            return (<br key={i} />)
        }else {
            return line
        }
    });
  }
}

export default Common
