import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import Payment from "../vo/Payment"
import PayJp from "../vo/PayJp"
import Paypal from "../vo/Paypal"
import Amazon from "../vo/Amazon"
import PaySetting from "../vo/PaySetting"


var CHANGE_EVENT = "change" // chenge evnetを定数にする
var UPDATED_EVENT = "updated"


var _item = new Payment()
var _list = []



var setDetail = (item) =>{
  _item = PaymentStore.createRecord(item)
}
var setList = (items) => {
  _list = items.map((item) =>{
    return PaymentStore.createRecord(item)
  })
}

var update = (name,value) => {
  _item = _item.set(name,value)
}
var updateIn = (name,value) => {
  _item = _item.setIn(name,value)
}

var PaymentStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },
  getList(){
    return _list
  },

  createPayJp(item){
    return new PayJp({
      key:item.key
    })
  },

  createPayPal(item){
    return new Paypal({
      client_id:item.client_id,
      secret:item.secret
    })
  },

  createAmazon(item){
    return new Amazon({
      client_id:item.client_id,
      secret:item.secret
    })
  },

  createSettingRecord(item){
    return new PaySetting({
      payjp:item.payjp ? PaymentStore.createPayJp(item.payjp) : new PayJp(),
      paypal:item.paypal ? PaymentStore.createPayPal(item.paypal) : new Paypal(),
      amazon:item.amazon ? PaymentStore.createAmazon(item.amazon) : new Amazon(),
      bank:item.bank
    })
  },


  createRecord(item){

    return new Payment(
    {
      id:item.id,
      name:item.name,
      method_type:item.method_type,
      setting:PaymentStore.createSettingRecord(item.setting)
    })
  },



  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする

    case Constants.PAYMENT_LIST_LOADED:
      setList(action.list);
      PaymentStore.emitChange()
      break;
    case Constants.PAYMENT_DETAIL_LOADED:
      setDetail(action.item);
      PaymentStore.emitChange()
      break;
    case Constants.PAYMENT_UPDATE:
      if(action.isin){
        updateIn(action.name,action.value);
      }else{
        update(action.name,action.value);
      }

      PaymentStore.emitChange()
      break
    case Constants.PAYMENT_UPDATED:

      setDetail(action.item);
      PaymentStore.emitChange(UPDATED_EVENT)
      break

    default:


      // no
  }
})
export default PaymentStore
