import AppDispatcher from "../dispatcher/AppDispatcher"
import Constants from "../constants/Constants"
import 'whatwg-fetch'
import config from 'react-global-configuration';



var ApiActions = {


  getDetail(category, id){

    fetch(config.get("API_JSON") + category + "/" + id + ".json", {
      method: 'GET',
      credentials: 'include'
    }).then(function(response) {

      if(response.status === 200){
        return response.json()
      }else{
        return null
      }
    }).then(function(data) {
      AppDispatcher.dispatch({
        actionType: Constants.API_GET_ITEM, // 誰に
        item: data
      })
    })
  },


}

export default ApiActions
