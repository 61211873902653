import React from "react"
import AuthActions from '../actions/AuthActions';
import AuthStore   from '../stores/AuthStore'
//import Header from "./parts/Header"
//import Footer from "./parts/Footer"
//import styles from "../styles/index.css"


export default class IndexApp extends React.Component {

  constructor(){
    super()
    this.authStoreErrorChangeHandler = this.authStoreErrorChangeHandler.bind(this)
    this.authStoreChangeHandler = this.authStoreChangeHandler.bind(this)
    this.changeUserNameHandler = this.changeUserNameHandler.bind(this)
    this.changePasswordHandler = this.changePasswordHandler.bind(this)
    this.goLogin = this.goLogin.bind(this)
    this.state = {
      username:undefined,
      password:undefined,
      message:undefined,
      is_loading:false
    }
  }
  componentDidMount(){
    AuthStore.addChangeListener(this.authStoreChangeHandler)
    AuthStore.addChangeListener(this.authStoreErrorChangeHandler,"auth_error")
  }

  componentWillUnmount(){
    AuthStore.removeChangeListener(this.authStoreChangeHandler)
    AuthStore.removeChangeListener(this.authStoreErrorChangeHandler,"auth_error")
  }

  authStoreErrorChangeHandler(){
    this.setState({
      message:"IDまたはPassowrdが異なります。"
    })
  }

  authStoreChangeHandler(){
    this.setState({
      is_loading:false
    })
    if(AuthStore.isAuth()){
      window.location = "/shop"
    }else{
      this.setState({
        message:"IDまたはPassowrdが異なります。"
      })
    }
  }



  goLogin(){
    this.setState({
      is_loading:true
    })
    AuthActions.auth(this.state.username, this.state.password)
  }

  changeUserNameHandler(e){
    const target = e.target
    this.setState({
      username:target.value
    })
  }
  changePasswordHandler(e){
    const target = e.target
    this.setState({
      password:target.value
    })
  }
  render(){

    var message = null
    if(this.state.message){
      message = this.state.message
    }else if(this.state.is_loading){
      message = <i className="fas fa-spinner fa-spin"></i>
    }

    return(
      <div>

        <main>

        <div className="main-pannel">

          <div className="logo">
            <img src="/image/logo_m.png" className="logo_login" alt="scrasy"/>
          </div>


          <section>
            <div className="form-box">

              <div className="form-box-item">
                <label htmlFor="number">ID</label>
                <input className="default-input" type="text"  name="loginnname" value = {this.state.username} onChange={this.changeUserNameHandler}/>
              </div>

              <div className="form-box-item">
                  <label htmlFor="number">Password</label>
                  <input className="default-input" type="password" name="password"  value = {this.state.password} onChange={this.changePasswordHandler}/>
              </div>
            </div>
          </section>
          <section>
            <div className="button-box">
              <div className="message">{message}</div>
              <button className="create-btn" onClick={this.goLogin}>ログイン</button>
            </div>
          </section>

        </div>
        </main>

      </div>
    )
  }
}
