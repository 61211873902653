import React from "react"




/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class FileUploader extends React.Component {

  constructor(){
    super()
    this.handleChangeFile = this.handleChangeFile.bind(this)
    this.closeBtnHandler = this.closeBtnHandler.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    //AuthActions.setAuth()
    this.state = {
      file:null,
      image_src:null,
      message:null
    }
  }

  componentDidMount(){
    //console.log(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberStoreChangeHandler)
    //MemberActions.getDetail(AuthStore.getToken())

  }

  componentWillUnmount(){
      //MemberStore.removeChangeListener(this.memberStoreChangeHandler)
  }

  /* Handlers */

  memberStoreChangeHandler(){
  }

  closeBtnHandler(){
    this.setState({
      file:null,
      image_src:null,
      message:null
    })
    this.props.closeBtnHandler()
  }

  handleChangeFile(e) {
    const files = e.target.files;
    if(files.length >0 ){
      const image_type = files[0].type

      console.log(image_type)

      if(image_type === "image/jpeg"
        || image_type === "image/png"
        /* || image_type === "application/pdf"
        || image_type === "audio/mpeg"
        || image_type === "audio/mp3"
        || image_type === "audio/mp4"
        || image_type === "video/mp4" */ ){

        const image_url = window.URL.createObjectURL(files[0]);
        this.setState(
          {image_src: image_url,
          mime_type:image_type,
          file:files[0],
        message:null}
        );
      }else{

        this.setState({
          image_src:null,
          message:"ファイルはJPG,PNG形式でアップしてください"
        })
      }
    }else{
      this.setState({
        image_src:null,
        message:"ファイルはJPG,PNG形式でアップしてください"
      })
    }


  }

  uploadFile(){
    this.props.uploadFile(this.state.file)
  }

  render(){

    if(!this.props.is_display){
      return null
    }

    const btnHtml = this.state.image_src ? <button className="create-btn middiup" onClick={this.uploadFile}>送信</button> : null
    return(
      <div class="over-flow-back">
        <div className="uploader">
          <div className="closebtn" onClick={this.closeBtnHandler}><i className="fas fa-times-circle"></i></div>
          <div className="message">{this.state.message}</div>
          <input type="file" name="file" onChange = {this.handleChangeFile}/>
          <div className="uploaded_img"><img id="uploaded_img" src={this.state.image_src} alt="toupload"/></div>
          {btnHtml}
        </div>
      </div>
    )
  }
}
