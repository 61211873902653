import React from "react"

import Header from "./parts/Header"
import SideMenuSetting from './parts/SideMenuSetting'
import Input from './parts/Input'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'
import SettingActions from '../actions/SettingActions'
import SettingStore from '../stores/SettingStore'
import TopAlertBox  from './parts/TopAlertBox'


export default class TaxApp extends React.Component {

  constructor(){
    super()
    this.settingStoreChangeHandler = this.settingStoreChangeHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)

    AuthActions.setAuth()
    this.methods = [{id:0,name:"一般税率(8%)"},{id:1,name:"非課税(0%)"}]
    this.state = {
      item:null,
      display_alert:false,
      is_processing:false,
      message:null
    }
  }

  componentDidMount(){

    SettingStore.addChangeListener(this.settingStoreChangeHandler)
    SettingActions.getList(AuthStore.getToken(),"TAX_TYPE")
    //MemberActions.getMemberList(AuthStore.getToken())

  }


  componentWillUnmount(){
    SettingStore.removeChangeListener(this.settingStoreChangeHandler)
  }
  settingStoreChangeHandler(){
    if(this.state.is_processing){
      this.setState({
        display_alert:true,
        message:"保存しました。",
        is_processing:false
      })
    }else{
      this.setState({
        display_alert:false
      })
    }
    this.setState({
      item:SettingStore.getDetail("TAX_TYPE"),

    })
  }
  changeValueHandler(name,value){
    SettingActions.update("TAX_TYPE",value)
  }
  clickHandler(){
    this.setState({
      display_alert:false,
      is_processing:true
    })
    SettingActions.updateExe(AuthStore.getToken(),SettingStore.getDetail("TAX_TYPE"))
  }

  render(){

    if(!this.state.item) return null
    const main_html = <section className="left-pannel">
      <span className="sml">*税率は個別の商品ごとにも設定できます</span>
      <Input type="radio" label="税率設定" name="tax_type" value={this.state.item.value ? this.state.item.value : 0 } items={this.methods} changeValueHandler={this.changeValueHandler}/>

      <div className="btn-box">
        <button className="create-btn" onClick = {this.clickHandler}>保存</button>
      </div>
        </section>


    const addclass = this.state.display_alert ? "in" : null

    return(
      <div>
      <TopAlertBox text={this.state.message} addclass={addclass} closeBtnHandler={this.closeBtnHandler}/>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <SideMenuSetting id="main" />
              </section>
              <section className="main-2-pannel">

                  {main_html}



              </section>
            </div>
          </main>
      </div>
    )
  }
}
