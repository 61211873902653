import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import NoticeMail from "../vo/NoticeMail"
import NoticeSlack from "../vo/NoticeSlack"


var CHANGE_EVENT = "change" // chenge evnetを定数にする



var _item = []

var createNew = (item) =>{
  _item = item
}


var update = (name,value) => {
  if(name === "timing"){
    if(_item.timing.indexOf(value) >= 0 ){
      _item.set("timing",_item.timing.splice(_item.timing.indexOf(value),1))
    }else{
      _item.set("timing",_item.timing.push(value))
    }
  }else{
    _item = _item.set(name,value)
  }

}


var NoticeStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },

  createMailRecord(item){

    return new NoticeMail({
      address: item.address,
      timing:item.timing
    })
  },

  createSlackRecord(item) {
    return new NoticeSlack({
      webhook: item.webhook,
      channel: item.channel,
      timing:item.timing
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.NOTICE_NEW:
      createNew(action.item)
      NoticeStore.emitChange()
    break
    case Constants.NOTICE_UPDATED:
      update(action.name,action.value);
      NoticeStore.emitChange()
      break;




    default:


      // no
  }
})
export default NoticeStore
