import React from "react"
import { Link } from 'react-router-dom'
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class ApiSideMenuSetting extends React.Component {

  constructor(){
    super()
    //AuthActions.setAuth()
    this.state = {

    }
  }

  componentDidMount(){
    //console.log(AuthStore.getToken())
    //MemberStore.addChangeListener(this.memberStoreChangeHandler)
    //MemberActions.getDetail(AuthStore.getToken())

  }

  componentWillUnmount(){
      //MemberStore.removeChangeListener(this.memberStoreChangeHandler)
  }

  /* Handlers */

  memberStoreChangeHandler(){


  }

  render(){


    return(

      <ul>
        <li><a href="/shop/settings"><i className="fas fa-sign-in-alt"></i>Auth API</a>
          <ul className="side-child">
            <li className="all"><Link to="/api/auth/Auth" onClick={this.props.updateParam}>Oauth認証</Link></li>
            <li className="all"><Link to="/api/auth/AuthSns" onClick={this.props.updateParam}>Oauth認証(SNS認証後)</Link></li>
            <li className="all"><Link to="/api/auth/Refresh Token" onClick={this.props.updateParam}>Refresh Token</Link></li>
            <li className="all"><Link to="/api/auth/LineToken" onClick={this.props.updateParam}>Line Token取得</Link></li>
          </ul>
        </li>

        <li><a href="/shop/settings"><i className="fas fa-store-alt"></i>Shop API</a>
          <ul className="side-child">
            <li className="shop"><Link to="/api/shop/InfoGet" onClick={this.props.updateParam}>ショップ情報取得</Link></li>
            <li className="shop"><Link to="/api/shop/InfoUpdate" onClick={this.props.updateParam}>ショップ情報更新</Link></li>
            <li className="shop"><Link to="/api/shop/SettingList" onClick={this.props.updateParam}>ショップ設定一覧取得</Link></li>
            <li className="shop"><Link to="/api/shop/SettingCreate" onClick={this.props.updateParam}>ショップ設定リスト作成</Link></li>
            <li className="shop"><Link to="/api/shop/SettingUpdate" onClick={this.props.updateParam}>ショップ設定更新</Link></li>
            <li className="shop"><Link to="/api/shop/SettingDelete" onClick={this.props.updateParam}>ショップ設定削除</Link></li>
            <li className="shop"><Link to="/api/shop/AccountList" onClick={this.props.updateParam}>アカウント一覧取得</Link></li>
            <li className="shop"><Link to="/api/shop/AccountGet" onClick={this.props.updateParam}>アカウント取得</Link></li>
            <li className="shop"><Link to="/api/shop/AccountCreate" onClick={this.props.updateParam}>アカウント作成</Link></li>
            <li className="shop"><Link to="/api/shop/AccountUpdate" onClick={this.props.updateParam}>アカウント更新</Link></li>
            <li className="shop"><Link to="/api/shop/AccountPassUpdate" onClick={this.props.updateParam}>アカウントパスワード更新</Link></li>
            <li className="shop"><Link to="/api/shop/AccountDelete" onClick={this.props.updateParam}>アカウント削除</Link></li>
            <li className="shop"><Link to="/api/shop/MailList" onClick={this.props.updateParam}>メールテンプレート一覧取得</Link></li>
            <li className="shop"><Link to="/api/shop/MailDetail" onClick={this.props.updateParam}>メールテンプレート詳細取得</Link></li>
            <li className="shop"><Link to="/api/shop/MailCreate" onClick={this.props.updateParam}>メールテンプレート新規作成</Link></li>
            <li className="shop"><Link to="/api/shop/MailUpdate" onClick={this.props.updateParam}>メールテンプレート更新</Link></li>
          </ul>

        </li>
        <li><a href="/shop/settings"><i className="fas fa-book-open"></i>Catalog API</a>
          <ul className="side-child">
            <li className="shop"><Link to="/api/catalog/ProductList" onClick={this.props.updateParam}>プロダクト一覧取得</Link></li>
            <li className="shop"><Link to="/api/catalog/ProductDetail" onClick={this.props.updateParam}>プロダクト取得</Link></li>
            <li className="shop"><Link to="/api/catalog/ProductCreate" onClick={this.props.createParam}>プロダクト新規作成</Link></li>
            <li className="shop"><Link to="/api/catalog/ProductUpdate" onClick={this.props.createParam}>プロダクト更新</Link></li>
            <li className="shop"><Link to="/api/catalog/ProductDelete" onClick={this.props.createParam}>プロダクト削除</Link></li>
            <li className="shop"><Link to="/api/catalog/FileAttach" onClick={this.props.createParam}>ファイルアタッチ</Link></li>
            <li className="shop"><Link to="/api/catalog/FileDisAttach" onClick={this.props.createParam}>ファイルアタッチ解除</Link></li>
            <li className="shop"><Link to="/api/catalog/MediaList" onClick={this.props.updateParam}>ファイル一覧取得</Link></li>
            <li className="shop"><Link to="/api/catalog/FileCreate" onClick={this.props.updateParam}>ファイル新規登録</Link></li>
            <li className="shop"><Link to="/api/catalog/MediaDelete" onClick={this.props.updateParam}>ファイル削除</Link></li>
            <li className="all"><Link to="/api/catalog/ProductListUser" onClick={this.props.updateParam}>プロダクト一覧取得</Link></li>
            <li className="all"><Link to="/api/catalog/ProductDetailUser" onClick={this.props.updateParam}>プロダクト取得</Link></li>
            <li className="all"><Link to="/api/catalog/PaymentMethodList" onClick={this.props.updateParam}>支払い方法取得</Link></li>
            <li className="all"><Link to="/api/catalog/DeliveryMethodList" onClick={this.props.updateParam}>配送方法取得</Link></li>
          </ul>
        </li>

        <li><a href="/shop/settings"><i className="fas fa-shopping-cart"></i>Cart API</a>
          <ul className="side-child">
            <li className="customer"><Link to="/api/cart/ItemList" onClick={this.props.updateParam}>カート内商品一覧</Link></li>
            <li className="customer"><Link to="/api/cart/ItemCreate" onClick={this.props.updateParam}>カート商品追加</Link></li>
            <li className="customer"><Link to="/api/cart/ItemUpdate" onClick={this.props.updateParam}>カート商品更新</Link></li>
            <li className="customer"><Link to="/api/cart/ItemDelete" onClick={this.props.updateParam}>カートクリア</Link></li>
            <li className="customer"><Link to="/api/cart/ItemUserUpdate" onClick={this.props.updateParam}>カートユーザー変更</Link></li>
            <li className="customer"><Link to="/api/cart/TradeList" onClick={this.props.updateParam}>取引一覧</Link></li>
            <li className="customer"><Link to="/api/cart/TradeCreate" onClick={this.props.updateParam}>取引作成</Link></li>
            <li className="customer"><Link to="/api/cart/TradeDetail" onClick={this.props.updateParam}>取引詳細</Link></li>
          </ul>
        </li>

        <li><a href="/shop/settings"><i className="fas fa-users"></i>Customer API</a>
          <ul className="side-child">
              <li className="all"><Link to="/api/customer/CreateTentitive" onClick={this.props.updateParam}>テンポラリーユーザー作成</Link></li>
              <li className="all"><Link to="/api/customer/CreateCustomer" >ユーザー作成</Link></li>
          </ul>
        </li>

      </ul>

    )
  }
}
