import React from "react"
import { Link } from 'react-router-dom'
import Header from "./parts/Header"
import SideMenuSetting from './parts/SideMenuSetting'
import NoticeEditor from './parts/NoticeEditor'
import AuthActions from '../actions/AuthActions'
import AuthStore from '../stores/AuthStore'
import NoticeStore from '../stores/NoticeStore'
import ShopActions from '../actions/ShopActions'
import ShopStore from '../stores/ShopStore'
import SettingActions from '../actions/SettingActions'
import SettingStore from '../stores/SettingStore'
import NoticeMail from "../vo/NoticeMail"
import NoticeSlack from "../vo/NoticeSlack"
import AlertBox from "./parts/AlertBox"

import config from 'react-global-configuration';
/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class NoticeApp extends React.Component {

  constructor(){
    super()
    this.shopStoreChangeHandler = this.shopStoreChangeHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.displayEditor = this.displayEditor.bind(this)
    this.closeBtnHandler = this.closeBtnHandler.bind(this)
    this.settingStoreHandler = this.settingStoreHandler.bind(this)
    this.deleteBtnHandlere = this.deleteBtnHandlere.bind(this)
    this.cancelHandler = this.cancelHandler.bind(this)
    this.deleteExeHander = this.deleteExeHander.bind(this)
    AuthActions.setAuth()
    this.state = {
      type:null,
      mail_notice:[],
      slack_notice:[],
      editor_display:false,
      selected_item:null,
      selected_id:null,
      delete_id:null,
      display_alert:false
    }
  }

  componentDidMount(){

    ShopStore.addChangeListener(this.shopStoreChangeHandler)
    SettingStore.addChangeListener(this.settingStoreHandler)
    ShopActions.getDetail(AuthStore.getToken())
    SettingActions.getList(AuthStore.getToken())

  }


  componentWillUnmount(){
    ShopStore.removeChangeListener(this.shopStoreChangeHandler)
    SettingStore.removeChangeListener(this.settingStoreHandler)
  }
  shopStoreChangeHandler(){
    this.setState({
      shop:ShopStore.getDetail()
    })
  }

  changeValueHandler(name,value){
    //console.log(name,value)
    //ShopActions.update(name,value)
  }
  settingStoreHandler(){

    this.setState({
      mail_notice:SettingStore.getListByType("NOTICE_MAIL"),
      slack_notice:SettingStore.getListByType("NOTICE_SLACK")
    })
  }
  clickHandler(){
    ShopActions.updateExe(AuthStore.getToken(),this.state.shop)
  }
  displayEditor(type){
    var item = null

    if(type === "mail"){
      item = new NoticeMail()
    }else if(type === "slack"){
      item = new NoticeSlack()
    }

    this.setState({
      selected_item:item,
      type:type,
      editor_display:true
    })
  }
  cancelHandler(){
    this.setState({
      display_alert:false
    })
  }
  deleteExeHander(){

    SettingActions.delete(AuthStore.getToken(),this.state.delete_id)
    this.setState({
      display_alert:false,
      delete_id:null
    })
  }
  closeBtnHandler(){
    this.setState({
      editor_display:false
    })
  }
  deleteBtnHandlere(id){
    //console.log(id)
    this.setState({
      display_alert:true,
      delete_id:id
    })
    //
  }
  editBthHandler(id, type){
    var item = null
    if(type === "mail"){
      item = NoticeStore.createMailRecord(JSON.parse(SettingStore.getById(id).value))
    }else if(type === "slack"){
      item = NoticeStore.createSlackRecord(JSON.parse(SettingStore.getById(id).value))
    }
    this.setState({
      selected_id:id,
      selected_item:item,
      editor_display:true,
      type:type
    })
  }
  render(){

    const editor = this.state.editor_display ? <NoticeEditor closeBtnHandler={this.closeBtnHandler} type={this.state.type} item={this.state.selected_item} id={this.state.selected_id}/> : null

    const mail_notice_html = this.state.mail_notice.map((_item) => {
      const item = JSON.parse(_item.value)
      return <li key={"notice_" + _item.id}><div className="notice-mailaddress">{item.address}</div><div>{item.timing.map((timing) => { return <span className="spacer">{config.get("NOTICE_"+timing)}</span>})}</div><div className="linkbtns"><span className="link" onClick={()=>this.editBthHandler(_item.id,"mail")}><i className="fas fa-edit"></i> 編集</span> <span className="link" onClick={() => this.deleteBtnHandlere(_item.id)}><i className="fas fa-minus-circle"></i> 削除</span></div></li>
    })

    const scack_notice_html = this.state.slack_notice.map((_item) => {
      const item = JSON.parse(_item.value)
      return <li ><div className="notice-mailaddress">{item.channel}</div><div>{item.timing.map((timing) => { return <span className="spacer">{config.get("NOTICE_"+timing)}</span>})}</div><div className="linkbtns"><span className="link"  onClick={()=>this.editBthHandler(_item.id,"slack")}><i className="fas fa-edit"></i> 編集</span> <span className="link" onClick={() => this.deleteBtnHandlere(_item.id)}><i className="fas fa-minus-circle"></i> 削除</span></div></li>
    })

    const alert_box = this.state.display_alert ? <AlertBox ok="削除する" cancel="キャンセル"　cancelHandler={this.cancelHandler} okHandler={this.deleteExeHander}/> : null

    return(
      <div>
        {editor}
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <SideMenuSetting id="main" />
              </section>
              <section className="main-2-pannel">

              <section className="header-info">
                <h2>メール通知</h2>
                <div className="head-right">
                  <Link to="#" onClick  = {() => this.displayEditor("mail") }><i className="fas fa-plus-circle"></i> 新規メール通知</Link>
                </div>

              </section>
              <ul className="list-type1">
                {mail_notice_html}
              </ul>


              <section className="header-info">
                <h2>Slack通知</h2>
                <div className="head-right">

                  <Link to="#" onClick  = {() => this.displayEditor("slack") }><i className="fas fa-plus-circle"></i> 新規Slack通知</Link>
                </div>
              </section>
              <ul className="list-type1">
                {scack_notice_html}
              </ul>


              </section>
            </div>
          </main>
          {alert_box}
      </div>
    )
  }
}
