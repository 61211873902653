import React from "react"
import config from 'react-global-configuration';

/*
var getInvoiceState = () => {
  return InvoiceStore.getAll()
}
*/

export default class Paging extends React.Component {

  constructor(){
    super()

    this.state = {

    }
  }

  componentDidMount(){


  }

  componentWillUnmount(){

  }

  /* Handlers */



  render(){

    
    const item_number = Math.ceil(this.props.total / Number(config.get("LIMIT")))
    const list_html = Array(item_number).fill(0).map((item,index) => {
      const selected_class = Number(this.props.offset) === index ? "selected" : ""
      return <div className={selected_class} key={"paging_" + index} onClick={() => this.props.handler(index)}>{(index + 1)}</div>
    })

    return(
      <div className="paging">
        {list_html}
      </div>
    )
  }
}
