import React from "react"

import Header from "./parts/Header"
import ApiSideMenuSetting from "./parts/ApiSideMenuSetting"



export default class ApiTopApp extends React.Component {

  constructor({match}){
    super()

    this.state = {

    }
  }

  componentDidMount(){



  }


  componentWillUnmount(){

  }




  render(){

    return(
      <div>
        <Header />
          <main>
            <div className="main-pannel-lr">
              <section className="side-menu-pannel">
                <ApiSideMenuSetting category={this.state.category} api={this.state.api} />
              </section>

              <section className="main-2-pannel api">
                <section className="header-info">
                  <h2>APIドキュメント</h2>
                </section>

                <section className="left-pannel">
                  公開済みAPIの説明です。サイドメニューよりAPIを選択してください。
                </section>

              </section>
            </div>
          </main>
      </div>
    )
  }
}
