import AppDispatcher from "../dispatcher/AppDispatcher"
import { EventEmitter } from "events"
import Constants from "../constants/Constants"
import assign from "object-assign"
import MailTemplate from "../vo/MailTemplate"



const CHANGE_EVENT = "change" // chenge evnetを定数にする
const UPDATED = "updated"


var _items = []
var _item = null
var setList = (items) =>{
  _items = items.map((item) => {
    return MailTemplateStore.createRecord(item)
  })
}

var setDetail = (item,id) =>{

  _item = item ? MailTemplateStore.createRecord(item) : new MailTemplate({send_type:id})
}

var update = (name,value) => {
  _item = _item.set(name,value)
}


var MailTemplateStore = assign({},EventEmitter.prototype,{

  getDetail(){
    return _item
  },
  getList(){
    return _items
  },
  createRecord(item){
    return new MailTemplate(
    {
      id:item.id,
      title:item.title,
      body:item.body,
      from_address:item.from_address,
      send_type:item.send_type
    })
  },

  emitChange(str =  CHANGE_EVENT){ // 何かアクションがあったことを知らせる
    this.emit(str)
  },

  addChangeListener(callback,str =  CHANGE_EVENT){ // リスナーに追加
    this.on(str, callback)
  },

  removeChangeListener(callback,str =  CHANGE_EVENT){ // リスナーから削除
    this.removeListener(str, callback)
  },

})

AppDispatcher.register((action)=>{

  switch(action.actionType){ // actionTypeでswitchする
    case Constants.MAILTEMPLATE_DETAIL_LOADED:
      setDetail(action.item,action.id)
      MailTemplateStore.emitChange()
    break
    case Constants.MAILTEMPLATE_LIST_LOADED :
      setList(action.list)
      MailTemplateStore.emitChange()
    break;

    case Constants.MAILTEMPLATE_UPDATED:

      update(action.name,action.value);
      MailTemplateStore.emitChange()
      break;

    case Constants.MAILTEMPLATE_SAVED:
      MailTemplateStore.emitChange(UPDATED)
      break;

    default:


      // no
  }
})
export default MailTemplateStore
