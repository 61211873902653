import { Record } from 'immutable'

const Shop = Record({
  id: null,
  name: null,
  tel: null,
  shop_key: null,
  mail: null,
  postcode:null,
  address1: null,
  address2: null,
  created_at: null,
  modify_at: null,
  discription: null,
  open_flg: null,
  group:null
});
export default Shop;
